import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

const ProjeCart = () => {
  const [data, setData] = useState(null);

  const fetchProduct = async () => {
    try {
      const response = await fetch(`https://renamesoft.com/r_api/api.php`, {
        method: "POST",

        body: JSON.stringify({
          q: "urunler_boost",
        }),
      });

      const data = await response.json();

      if (data.urunler) {
        setData(data.urunler);
      }

      if (data.error) {
        console.error("Sunucu hatası:", data.error);
      }
    } catch (err) {
      console.error("Bir hata oluştu:", err);
    }
  };

  useEffect(() => {
    fetchProduct();
  }, []);
  return (
    <section className="w-full min-h-screen flex justify-center">
      <div className="lg:w-4/5 w-full flex gap-4 flex-wrap justify-center items-center ">
        {data &&
          data.map((item, index) => (
            <div key={index} className="lg:w-1/4 md:w-1/3 w-full border h-96">
              <div className="w-full h-2/3 border-b">
                <img
                  src={item.image}
                  alt={item.name}
                  className="w-full h-full object-fill"
                />
              </div>
              <div className="w-full h-1/3 flex flex-col justify-around items-center ">
                <h2>{item.name}</h2>
                <NavLink
                  to={"/yazilimlar/" + item.seo + ""}
                  className={
                    "h-14 w-2/3 border rounded-xl bg-blue-400 text-white flex justify-center items-center "
                  }
                >
                  Detaylara Git
                </NavLink>
              </div>
            </div>
          ))}
      </div>
    </section>
  );
};

export default ProjeCart;
