import React, { useEffect, useState } from 'react'

const Projeler = () => {
    const screenWidth = window.innerWidth

    const sliderImages = [
        { url: "/image/erce_mac.png", site_link: "https://erceglobal.com" },
        { url: "/image/fn_mac.png", site_link: "https://fnaksesuar.com" },
        { url: "/image/genc_mac.png", site_link: "https://antalyayazicitamiri.net/" },
        // Diğer resimler
    ];

    const sliderMobilImages = [
        { url: "/image/mobil_erce2.png", site_link: "https://erceglobal.com" },
        { url: "/image/fn_mobil.png", site_link: "https://fnaksesuar.com" },
        { url: "/image/genc_mobil.png", site_link: "https://antalyayazicitamiri.net/" },
        // Diğer resimler
    ];

    const [currentIndex, setCurrentIndex] = useState(0);
    const totalImages = sliderImages.length;

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex === totalImages - 1 ? 0 : prevIndex + 1));
        }, 3000);

        return () => clearInterval(interval);
    }, [totalImages]);
    return (
        <section className='min-h-screen  flex flex-col justify-center items-center gap-3'>
            <div className='flex flex-wrap justify-around items-center  h-full w-3/4 gap-4 ' >
                {screenWidth > 1024 && (
                    <img src={sliderImages[currentIndex].url} alt="" className='object-fill h-1/2 ' />

                )}
                <img src={sliderMobilImages[currentIndex].url} alt="" className='object-fill  w-72 h-1/4  lg:h-1/5 ' />
            </div>
        </section>
    )
}

export default Projeler

