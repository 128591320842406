import React, { useEffect, useState } from "react";
import { NavLink, Navigate, useParams } from "react-router-dom";
import Navbar from "../../components/common/header/Navbar";
import Footer from "../../components/common/footer/Footer";
const Yazilimlar = ({ sepet, setSepet }) => {
  const { name } = useParams();
  const [filter, setFilter] = useState(null);
  const fetchProduct = async () => {
    try {
      const response = await fetch(`https://renamesoft.com/r_api/api.php`, {
        method: "POST",

        body: JSON.stringify({
          q: "single_urun",
          seo: name,
        }),
      });

      const data = await response.json();

      if (data.urun) {
        setFilter(data.urun);
      }

      if (data.error) {
        console.error("Sunucu hatası:", data.error);
      }
    } catch (err) {
      console.error("Bir hata oluştu:", err);
    }
  };

  const add_basket = async () => {
    try {
      const response = await fetch(`https://renamesoft.com/r_api/api.php`, {
        method: "POST",

        body: JSON.stringify({
          q: "add_basket",
          id: filter?.product_id,
        }),
      });

      const data = await response.json();

      if (data.status) {
        alert("Sepete eklendi");
      } else {
        alert("Sepete eklenemedi");
      }
    } catch (err) {
      alert("Bir hata olustu daha sonra tekrar deneyin. ");
    }
  };

  const sepetEkle = () => {
    add_basket();
    setSepet([...sepet, filter]);
  };
  useEffect(() => {
    fetchProduct();
  }, []);

  return (
    <main>
      <Navbar setSepet={setSepet} sepet={sepet} />
      <section className="absolute top-24  w-full flex flex-col justify-center items-center ">
        <div className="lg:w-3/5 w-full min-h-screen border flex flex-col p-10  gap-10">
          <div className="w-full ">
            <img
              src={filter?.image ? filter?.image : ""}
              alt=""
              className="object-fill mx-auto "
            />
          </div>
          <div className="w-full">
            <div className="text-center text-3xl font-bold">
              {filter?.title}
            </div>
            <div className="flex flex-wrap">
              <div className="w-full flex flex-col gap-5 lg:text-center ">
                <p>
                  Web sitenizin ihtiyaçlarına uygun, şık ve işlevsel hazır
                  temalarımızla dijital varlığınızı güçlendirin. Sunduğumuz
                  geniş koleksiyon, çeşitli sektörlere uygun özelleştirilebilir
                  temaları içerir. Her biri profesyonel tasarım ve kullanıcı
                  dostu deneyimiyle öne çıkar.
                </p>
                <h5 className="text-center text-lg font-semibold">
                  Neden Hazır Temalarımızı Tercih Etmelisiniz?
                </h5>

                <div>
                  <span className="font-bold">Çeşitlilik ve Esneklik:</span>{" "}
                  Farklı sektörlere ve ihtiyaçlara yönelik geniş bir tema
                  yelpazesi.
                </div>
                <div>
                  <span className="font-bold">Kullanım Kolaylığı:</span> Önceden
                  tasarlanmış ve kullanıma hazır temalarımız, kolay kurulum ve
                  özelleştirme imkanı sunar.
                </div>
                <div>
                  <span className="font-bold">Profesyonel Tasarım:</span>{" "}
                  Modern, göz alıcı ve estetik açıdan etkileyici tasarımlar.
                </div>
                <div>
                  <span className="font-bold">Mobil Uyumlu:</span> Her bir tema,
                  mobil cihazlarda mükemmel performans sergiler.
                </div>
                <h5 className="text-center text-lg font-semibold">
                  Satın Alma Süreci Nasıl İşler?
                </h5>
                <div>
                  <span className="font-bold">Tema Seçimi:</span> İhtiyacınıza
                  uygun temayı seçin.
                </div>
                <div>
                  <span className="font-bold">Özelleştirme:</span> Seçtiğiniz
                  temayı sitenizin kimliğiyle uyumlu hale getirin.
                </div>
                <div>
                  <span className="font-bold">Satın Alma:</span> Güvenli ödeme
                  sistemimizle temanızı satın alın.
                </div>
                <div>
                  <span className="font-bold">Kullanıma Başlama:</span> Hemen
                  temanızı kullanmaya başlayın ve web sitenizi güçlendirin.
                </div>
                <h5 className="text-center text-lg font-semibold">
                  Destek ve Güncelleme
                </h5>
                <p>
                  Müşteri memnuniyeti odaklı ekibimiz, her adımda size yardımcı
                  olmak için burada. Ayrıca, temalarımız düzenli olarak
                  güncellenir ve desteklenir, böylece her zaman en güncel
                  özelliklere erişebilirsiniz. Web sitenizi güzelleştirmek için
                  bugün koleksiyonumuzu keşfedin ve işinizi veya projenizi
                  yansıtan mükemmel tema ile tanışın. Not: Temalarımızın
                  kullanım koşulları ve lisans politikaları hakkında detaylı
                  bilgi için lütfen sitemizi ziyaret edin.
                </p>

                <p>{filter?.description}</p>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col gap-5 justify-end items-end">
            <div className="flex gap-4">
              <span
                className="font-bold text-red-500"
                style={{ textDecoration: "line-through" }}
              >
                {filter?.price * 1.5} ₺
              </span>
              <span className="font-bold text-green-500">
                {filter?.price} ₺
              </span>
            </div>
            <NavLink
              onClick={() => sepetEkle()}
              className="w-40 h-12 mb-20 bg-blue-500 text-white rounded-xl flex justify-center items-center"
            >
              Satın Al
            </NavLink>
          </div>
        </div>
        <Footer />
      </section>
    </main>
  );
};

export default Yazilimlar;
