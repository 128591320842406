import React from 'react'
import {FaHeadset} from 'react-icons/fa'
import { NavLink } from 'react-router-dom'
const Destek = () => {
    return (
        <NavLink to={'https://api.whatsapp.com/send?phone=905321349003'} target='_blank' className='h-12 w-32 border rounded-xl bg-green-400 text-white flex justify-center items-center gap-3 fixed bottom-2 right-5 ' >
            <span className='font-bold'>7/24</span>
            <FaHeadset className='w-5 h-5' />
        </NavLink>
    )
}

export default Destek