import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="flex lg:h-[200px] px-10  bg-[#ECECEC] flex-wrap justify-between gap-5 lg:gap-0 w-full items-center  border ">
      <div className="flex flex-wrap lg:justify-center gap-7  lg:w-1/4  pl-5 items-center">
        <div className="flex">
          <img
            src="/image/logo.png"
            alt="logo"
            className="object-cover  h-14 w-14 lg:h-16 lg:w-16"
          />
          <div className="flex flex-col justify-center items-center">
            <div className="text-2xl font-semibold">Renamesoft</div>
            <div className="text-sm font-thin">Change The Future</div>
          </div>
        </div>
        <img
          src="https://fuatayazgroup.com/img/fuatyaz.png"
          alt=""
          className="object-cover w-52"
        />
      </div>
      <div className="flex flex-wrap text  w-full lg:w-1/4 text-lg justify-center items-center ">
        <h4 className="w-full text-xl">Web ve Mobil uygulamalar</h4>
        <p className="text-sm">
          Global düzeyde tüm yenilikleri ve güncel trendleri yakından takip
          ederek yazılım alanında ürün ve hizmetlerimizi sizlerle
          buluşturuyoruz. Özgün web tasarım, kurumsal kimlik, dijital pazarlama,
          sosyal medya yönetimi ve e-ticaret danışmanlığı hizmetlerimizle ile iş
          yükünüzü hafifletiyoruz.
        </p>
        <div className="w-full flex gap-5">
          <NavLink className="text-sm" to={"/iade-politikasi} "}>
            iade politikası
          </NavLink>
          <NavLink className="text-sm" to={"/gizlilik"}>
            Gizlilik Sözleşmesi
          </NavLink>
        </div>
      </div>
      <div className="flex flex-wrap   w-full text-lg justify-end  lg:w-1/6 items-center ">
        <img src="/image/iyzico.webp" alt="" className="object-cover  h-20" />
      </div>
      <div className="flex flex-col   lg:items-end w-full lg:w-1/12 text-lg ">
        <h4 className="text-xl">İletişim Bilgileri</h4>
        <p className="text-sm">
          <NavLink to="tel:+905321349003">+90 532 134 90 03</NavLink> <br />{" "}
          <NavLink to="tel:+908503085889">+90 850 308 58 89</NavLink> <br />{" "}
          info@renamesoft.com
        </p>
      </div>
    </footer>
  );
};

export default Footer;
