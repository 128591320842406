import React from "react";
import { Link } from "react-router-dom";

export default function Card({link,image}) {
  return (
    <div
    
    data-aos="fade-up"
    className="w-full ml-32  h-full border-2 flex flex-col justify-center item-center rounded-2xl relative
      shadow-2xl shadow-#706d6d  xl:m-3
    ">

        <img src={image} alt="chatlogo" className=" w-full h-full object-contain " />
      <Link to={link} target="_blank">
        <button className="border-2 border-gray-900  hover:bg-gray-900 hover:text-white rounded-xl text-gray-900 font-bold py-2 px-4    m-5 ">
          İncele
        </button>
      </Link>
    </div>
  );
}
