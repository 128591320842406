import React from 'react'
import 'aos/dist/aos.css';
import { NavLink } from 'react-router-dom';
const Contact = () => {
    const phoneRegex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;


    const onChangeHandle = (e) => {
        const { name, value } = e.target;
        console.log(name, value);


    }
    const onSubmitHandle = (e) => {
        e.preventDefault();
        var formData = new FormData(e.target);
        for (let entry of formData.entries()) {
            if (entry[0] === "phone") {
                if (!phoneRegex.test(entry[1])) {
                    alert("Lütfen telefon numaranızı kontrol ediniz. numaranızın başına +90 koyunuz.");
                    return;
                }
                if (entry[1].length !== 13) {
                    alert("Lütfen telefon numaranızı kontrol ediniz.");
                    return;
                }
            }
            if (entry[0] === "email") {
                if (!emailRegex.test(entry[1])) {
                    alert("Lütfen email adresinizi kontrol ediniz.");
                    return;
                }
            }
            if (entry[1] === "") {
                alert("Lütfen tüm alanları doldurunuz.");
                return;
            }
        }

        fetch('api/contact', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        }).then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                alert("Mesajınız alındı. Tesekkurler.");
            })
            .catch((error) => {
                console.error('Error:', error);
                alert("Bir hata oluştu daha sonra tekrar deneyiniz.");

            })

    }
    return (
        <div className='w-full flex flex-col gap-5 h-screen  justify-center items-center' >
            <div className='flex flex-col gap-5 justify-center  w-full items-center h-2/3'>
                <div data-aos="fade-down " className='italic cursive' >teknoloji dünyasına adım atmak için</div>
                <div data-aos="fade-down" className='text-2xl font-mono' >Bizimle İletişime Geçin</div>
                <div className='flex flex-wrap justify-evenly items-center gap-2 w-full'>
                    <form onSubmit={onSubmitHandle} data-aos="fade-right" data-aos-duration="200" className=' lg:h-[450px]   w-full lg:max-w-4xl   rounded-2xl flex flex-col justify-center items-center '>
                        <div className='flex flex-wrap justify-center items-center w-full gap-3' >
                            <div className='flex flex-col justify-center items-center gap-2' >
                                <div  >Adınız</div>
                                <input onChange={onChangeHandle} name='name' typeof='text' className='border w-80 h-8 rounded-lg outline-none pl-2' type="text" />
                            </div>
                            <div className='flex flex-col justify-center items-center gap-2' >
                                <div className='text-lg ' >Mail Adresiniz</div>
                                <input onChange={onChangeHandle} name='email' typeof='email' className='border w-80 h-8 rounded-lg outline-none pl-2' type="text" />
                            </div>
                            <div className='flex flex-col justify-center items-center gap-2' >
                                <div className='text-lg ' >Telefon Numaranız</div>
                                <input onChange={onChangeHandle} name='phone' typeof='number' className='border w-80 h-8 rounded-lg outline-none pl-2' type="text" />
                            </div>

                            <div className='flex flex-col justify-center items-center gap-2' >
                                <div className='text-lg ' >İçerik Konusu</div>
                                <input onChange={onChangeHandle} name='subject' typeof='text' className='border w-80 h-8 rounded-lg outline-none pl-2' type="text" />
                            </div>

                            <div className='flex flex-col w-3/4 justify-center items-center gap-2' >
                                <div className='text-lg ' >Mesajınız</div>
                                <textarea onChange={onChangeHandle} name='message' className='border w-full h-40 max-h-40  rounded-lg  outline-none pl-2' type="text" />
                            </div>

                            <div className='flex w-3/4 ' >
                                <button type='submit' className='bg-gray-300 w-full h-8 rounded-lg' >Gönder</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    )
}

export default Contact