import React from 'react'

const Gizlilik = () => {
  return (
    <main className='h-screen w-full flex justify-center items-center' >
        <div className='h-full w-3/5 border flex flex-col justify-center items-center px-20 ' >
            <h1 className='text-3xl' >Gizlilik Sözlesmesi</h1>
            <p>Toplanan Bilgiler: Biz Renamesoft, kullanıcıların kişisel bilgilerini (ad, e-posta adresi, telefon numarası vb.) belirli amaçlar doğrultusunda toplayabiliriz. Bu bilgiler, hizmetlerimizi sunmak, geliştirmek, iletişim kurmak ve soruları yanıtlamak amacıyla kullanılabilir.
Çerezler: Web sitemiz veya uygulamamız, kullanıcı deneyimini iyileştirmek ve hizmetlerimizi optimize etmek için çerezler (cookies) kullanabilir. Kullanıcılar, çerezlerin kullanımını kabul veya reddetme seçeneğine sahiptir. Ancak, bazı özelliklerin düzgün çalışabilmesi için çerezleri kabul etmek gerekebilir.
Hukuki Gereklilikler: Şirketimiz, yasal bir gereklilik veya mahkeme kararı çerçevesinde kişisel verilerinizi ifşa edebilir.
Veri Güvenliği: Kişisel bilgilerin güvenliğini sağlamak için endüstri standardı güvenlik önlemlerini alıyoruz. Ancak, internet üzerinden iletilen verilerin %100 güvenli olamayabileceğini unutmayın.
Kişisel Verilere Erişim ve Düzeltme: Kullanıcılar, kişisel bilgilerine erişme, düzeltme ve silme hakkına sahiptirler. Bu hakları kullanmak için lütfen info@renamesoft.com ile iletişime geçin.</p>
        </div>
    </main>
  )
}

export default Gizlilik