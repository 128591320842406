import React, { useEffect } from 'react'
import Navbar from '../../components/common/header/Navbar'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Contact from '../../components/contact/Contact';
import Footer from '../../components/common/footer/Footer';
import Destek from '../../components/common/footer/Destek';
const Home = ({ sepet, setSepet }) => {
    useEffect(() => {
        AOS.init();
    }, [])
    return (
        <main >
            <Navbar setSepet={setSepet} sepet={sepet} />

            <div className='absolute  top-24' >
                <Contact />
                <Footer />
                <Destek/>
            </div>

        </main>
    )
}

export default Home