import React from "react";
import Navbar from "../../components/common/header/Navbar";
import Footer from "../../components/common/footer/Footer";
import { NavLink } from "react-router-dom";

const Sepet = ({ sepet, setSepet }) => {
  const onRemoveHandler = (item) => {
    fetch(`https://renamesoft.com/r_api/api.php`, {
      method: "POST",
      body: JSON.stringify({
        q: "delete_basket",
        id: item.id,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status) {
          setSepet(data.sepet);
        } else {
          alert("Sepetten ürün silinemedi");
        }
      })
      .catch((err) => {
        alert("Sepetten ürün silinemedi");
      });
  };
  return (
    <main>
      <Navbar setSepet={setSepet} sepet={sepet} />

      <section className="absolute top-24">
        <div className="h-screen w-full flex flex-col justify-center items-center">
          <h4 className="text-3xl mb-5">Sepetim</h4>
          <div className="lg:w-3/5 w-full border h-1/2 overflow-y-auto ">
            {sepet.length > 0 ? (
              sepet.map((item, index) => (
                <div
                  className="h-40 px-10 w-full flex justify-between items-center"
                  key={index}
                >
                  <img src={item.image} className="h-32 object-fill" />
                  <p>{item.name}</p>
                  <p>{item.price}₺</p>
                  <div
                    onClick={() => onRemoveHandler(item)}
                    className="cursor-pointer"
                  >
                    Sil
                  </div>
                </div>
              ))
            ) : (
              <div className="w-full h-full flex justify-center items-center">
                <h1>Sepetinizde ürün bulunmamaktadır</h1>
              </div>
            )}
          </div>
          <div className="lg:w-3/5 w-full  h-52 flex justify-end items-center ">
            <div className="w-1/3  flex flex-col justify-center items-center">
              <div>
                Sepet Toplam :{" "}
                <span>
                  {sepet.reduce((acc, item) => acc + parseFloat(item.price), 0)}
                  ₺
                </span>
              </div>
              <NavLink
                to={"/odeme"}
                className="w-full h-10 bg-blue-400 text-white flex justify-center items-center"
              >
                Ödemeye Geç
              </NavLink>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </main>
  );
};

export default Sepet;
