import React, { useEffect } from 'react'
import Navbar from '../../components/common/header/Navbar'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Footer from '../../components/common/footer/Footer'
import Destek from '../../components/common/footer/Destek'
import Card from '../../components/Card/Card'
const Home = ({ sepet, setSepet }) => {
  useEffect(() => {
    AOS.init()
  }, [])
  return (
    <main className='relative '>
      <Navbar setSepet={setSepet} sepet={sepet} />
      <div className=' absolute top-24 min-h-screen  w-full flex flex-col gap-5 justify-center items-center p-36 xl:p-5 '>
        <h2 className='text-5xl font-sans mb-3 drop-shadow-lg dropshadow-black xl:text-center'>
          Yaptığımız Çalışmalar
        </h2>
        <div className='mobil mb-36'>
          <h3 className='text-4xl text-left my-2 font-semibold font-mono'>
            Mobil Uygulamalarımız
          </h3>
          <div className='w-full flex justify-center items-center flex-wrap  '>
            <img
              src='./image/mobil1.png'
              alt='site'
              className='w-56 hover:scale-150 transition ease-in-out delay-150 drop-shadow-md shadow-black '
            />
            <img
              src='./image/mobil2.png'
              alt='site'
              className='w-56 hover:scale-150 transition ease-in-out delay-150 drop-shadow-md shadow-black '
            />
            <img
              src='./image/mobil3.png'
              alt='site'
              className='w-56 hover:scale-150 transition ease-in-out delay-150 drop-shadow-md shadow-black '
            />
            <img
              src='./image/mobil4.png'
              alt='site'
              className='w-56 hover:scale-150 transition ease-in-out delay-150 drop-shadow-md shadow-black '
            />
            <img
              src='./image/mobil6.png'
              alt='site'
              className='w-56 hover:scale-150 transition ease-in-out delay-150 drop-shadow-md shadow-black '
            />
            <img
              src='./image/mobil5.png'
              alt='site'
              className='w-56 hover:scale-150 transition ease-in-out delay-150 drop-shadow-md shadow-black '
            />
          </div>
          <div class='container mx-auto py-8'>
            <section class='text-center'>
              <h2 class='text-3xl font-bold mb-4'>
                Mobil Uygulama Geliştirme Hizmetimiz
              </h2>
              <p class='text-lg'>
                Dijital dönüşümünüzü hızlandırmak ve kullanıcılarınıza mükemmel
                bir deneyim sunmak için güçlü mobil uygulamalar geliştiriyoruz.
              </p>
            </section>

            <section class='mt-8'>
              <h2 class='text-2xl font-bold mb-4'>Neden Biz?</h2>
              <ul class='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4'>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <h3 class='text-lg font-semibold mb-2'>
                    Özelleştirilmiş Çözümler
                  </h3>
                  <p>
                    İşletmenizin ihtiyaçlarına ve hedeflerine uygun olarak
                    özelleştirilmiş mobil uygulamalar geliştiriyoruz.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <h3 class='text-lg font-semibold mb-2'>
                    Kullanıcı Odaklı Tasarım
                  </h3>
                  <p>
                    Kullanıcıların ihtiyaçlarını ve beklentilerini merkeze
                    alarak, sezgisel ve kullanıcı dostu arayüzler oluşturuyoruz.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <h3 class='text-lg font-semibold mb-2'>
                    Teknolojik Yetkinlik
                  </h3>
                  <p>
                    Alanında uzman ve deneyimli bir ekip olarak, en son
                    teknolojileri ve trendleri takip ediyoruz.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <h3 class='text-lg font-semibold mb-2'>
                    Güvenlik ve Veri Gizliliği
                  </h3>
                  <p>
                    Müşterilerinizin güvenliği ve veri gizliliği bizim için ön
                    plandadır.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <h3 class='text-lg font-semibold mb-2'>
                    Sürekli Destek ve Bakım
                  </h3>
                  <p>
                    Uygulamanızın sorunsuz bir şekilde çalışması için sürekli
                    olarak destek ve bakım hizmeti sunuyoruz.
                  </p>
                </li>
              </ul>
            </section>
          </div>
        </div>
        <h3 className='text-4xl text-left my-2 font-semibold font-mono'>
          Web Uygulamalarımız
        </h3>
        <div
          data-aos='fade-right'
          data-aos-easing='linear'
          data-aos-duration='1000'
          className='w-full mx-12 flex mb-32 xl:flex-col  xl:my-0'
        >
          <Card link={'https://sertaygrup.com/'} image={'./image/site2.png'} />
          <div className='p-12 xl:p-6'>
            <h3 className='text-3xl font-mono my-2'>
              E-ticaret Siteleri için neler yapıyoruz
            </h3>
            <ul class='flex flex-col gap-4'>
              <li class='border border-gray-200 rounded-lg p-4'>
                <strong class='text-lg font-semibold mb-2 block'>
                  Ürün Satışı:
                </strong>
                <p>
                  Geniş bir ürün yelpazesi sunarak müşterilere giyimden
                  elektroniğe, ev eşyalarından kozmetiğe kadar çeşitli
                  kategorilerde ürünleri temin etme imkanı sağlıyoruz.
                </p>
              </li>
              <li class='border border-gray-200 rounded-lg p-4'>
                <strong class='text-lg font-semibold mb-2 block'>
                  Güvenli Ödeme İşlemleri:
                </strong>
                <p>
                  Kredi kartı, banka havalesi, kapıda ödeme gibi çeşitli ödeme
                  seçenekleriyle güvenli alışveriş deneyimi sunuyoruz.
                </p>
              </li>
              <li class='border border-gray-200 rounded-lg p-4'>
                <strong class='text-lg font-semibold mb-2 block'>
                  Ürün İnceleme ve Değerlendirme:
                </strong>
                <p>
                  Müşterilerin satın aldıkları ürünleri inceleyip
                  değerlendirebilmeleri için platformumuzda kapsamlı bir
                  inceleme ve değerlendirme sistemi bulunmaktadır. Bu, diğer
                  müşterilerin satın alma kararlarını desteklemeye yardımcı
                  olur.
                </p>
              </li>
              <li class='border border-gray-200 rounded-lg p-4'>
                <strong class='text-lg font-semibold mb-2 block'>
                  Kampanya ve İndirimler:
                </strong>
                <p>
                  Belirli zamanlarda veya koşullarda özel indirimler,
                  kampanyalar ve promosyonlar sunarak müşterilerimizi memnun
                  etmeyi ve sadakatlerini artırmayı hedefliyoruz.
                </p>
              </li>
              <li class='border border-gray-200 rounded-lg p-4'>
                <strong class='text-lg font-semibold mb-2 block'>
                  Hızlı Teslimat ve Kargo Takibi:
                </strong>
                <p>
                  Müşterilerimize en hızlı teslimatı sağlamak için güçlü kargo
                  şirketleri ile işbirlikleri kuruyoruz ve siparişlerinin her
                  adımını takip etmelerini sağlayacak kargo takip sistemi
                  sunuyoruz.
                </p>
              </li>
              <li class='border border-gray-200 rounded-lg p-4'>
                <strong class='text-lg font-semibold mb-2 block'>
                  Müşteri Hizmetleri ve Destek:
                </strong>
                <p>
                  7/24 erişilebilir müşteri hizmetleri ve destek ekibimizle
                  müşterilerimizin tüm sorularını, şikayetlerini veya
                  ihtiyaçlarını profesyonel ve etkili bir şekilde çözüme
                  kavuşturuyoruz.
                </p>
              </li>
              <li class='border border-gray-200 rounded-lg p-4'>
                <strong class='text-lg font-semibold mb-2 block'>
                  Ürün İade ve Değişim Politikaları:
                </strong>
                <p>
                  Müşterilerimizin memnuniyetsizlik durumunda kolaylıkla ürün
                  iade veya değişim yapabilmelerini sağlayan esnek iade ve
                  değişim politikaları sunuyoruz.
                </p>
              </li>
              <li class='border border-gray-200 rounded-lg p-4'>
                <strong class='text-lg font-semibold mb-2 block'>
                  Muhasebe Sistemi:
                </strong>
                <p>
                  İşletmemizin finansal sağlığını güvence altına almak için
                  profesyonel bir muhasebe sistemiyle muhasebe işlemlerini takip
                  etme ve değerlendirme işlemlerini yapma imkanı sunuyoruz.
                </p>
              </li>
              <li class='border border-gray-200 rounded-lg p-4'>
                <strong class='text-lg font-semibold mb-2 block'>
                  Mobil Uyumlu Tasarım:
                </strong>
                <p>
                  Web sitemizin mobil cihazlarda sorunsuz bir şekilde
                  görüntülenmesini sağlayan modern bir tasarım. Her zaman ve her
                  yerden bize erişebilmeniz için mobil uyumlu bir deneyim
                  sunuyoruz.
                </p>
              </li>
              <li class='border border-gray-200 rounded-lg p-4'>
                <strong class='text-lg font-semibold mb-2 block'>
                  SEO Desteği:
                </strong>
                <p>
                  Web uygulamanızı arama motorlarında daha görünür hale getirmek
                  için SEO dostu tekniklerle tasarlıyoruz. Böylece potansiyel
                  müşterilerinizin sizi daha kolay bulmasını ve işletmenize
                  ulaşmasını sağlıyoruz.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div
          data-aos-easing='linear'
          data-aos-duration='1000'
          data-aos='fade-left'
          className='w-full mx-12  flex flex-row-reverse my-32 flex-wra  xl:flex-col
          xl:my-0
          
          '
        >
          <Card link={'https://erceglobal.com/'} image={'./image/site5.png'} />
          <div className='p-12 xl:p-6'>
            <h3 className='text-3xl font-mono my-3'>
              Hizmet Siteleri için neler yapıyoruz
            </h3>
            <ul class='flex flex-col gap-4'>
              <li class='border border-gray-200 rounded-lg p-4'>
                <strong class='text-lg font-semibold mb-2 block'>
                  Hizmet Kategorileri:
                </strong>
                <p>
                  Geniş bir hizmet yelpazesi sunarak kullanıcıların
                  ihtiyaçlarını karşılamak için çeşitli kategorilerde hizmetler
                  sunuyoruz. Temizlik, bakım, taşıma gibi farklı alanlarda
                  kaliteli hizmetlerimizle müşterilerimizin güvenini
                  kazanıyoruz.
                </p>
              </li>
              <li class='border border-gray-200 rounded-lg p-4'>
                <strong class='text-lg font-semibold mb-2 block'>
                  Üye Kaydı ve Profil Yönetimi:
                </strong>
                <p>
                  Kullanıcılarımıza özel hesap oluşturma imkanı sunarak
                  kişiselleştirilmiş deneyimler sağlıyoruz. Profil yönetimi ile
                  kullanıcılarımızın tercihlerini ve geçmiş işlemlerini kolayca
                  yönetmelerini sağlıyoruz.
                </p>
              </li>
              <li class='border border-gray-200 rounded-lg p-4'>
                <strong class='text-lg font-semibold mb-2 block'>
                  Hizmet Sağlayıcılarının Profil Sayfaları:
                </strong>
                <p>
                  Hizmet sunan işletmelerin veya bireylerin özel profillerini
                  oluşturarak kaliteli hizmetlerini tanıtmalarına ve müşteri
                  güvenini kazanmalarına olanak sağlıyoruz. Profil sayfaları,
                  detaylı bilgiler, referanslar ve müşteri yorumları ile
                  zenginleştirilmiştir.
                </p>
              </li>
              <li class='border border-gray-200 rounded-lg p-4'>
                <strong class='text-lg font-semibold mb-2 block'>
                  Rezervasyon ve Randevu Yönetimi:
                </strong>
                <p>
                  Kolay ve hızlı rezervasyon imkanı sunarak müşterilerimizin
                  zamanını değerli kılıyoruz. Esnek randevu yönetimi ile
                  müşterilerimizin istedikleri zaman hizmet alabilmesini
                  sağlıyoruz.
                </p>
              </li>
              <li class='border border-gray-200 rounded-lg p-4'>
                <strong class='text-lg font-semibold mb-2 block'>
                  Ödeme Entegrasyonu:
                </strong>
                <p>
                  Güvenli ödeme altyapısı ile kullanıcılarımıza kolay ve güvenli
                  ödeme seçenekleri sunuyoruz. Kredi kartı, banka transferi gibi
                  çeşitli ödeme yöntemleri ile hızlı ve güvenli ödemeleri
                  sağlıyoruz.
                </p>
              </li>
              <li class='border border-gray-200 rounded-lg p-4'>
                <strong class='text-lg font-semibold mb-2 block'>
                  İnceleme ve Değerlendirme Sistemi:
                </strong>
                <p>
                  Müşterilerimizin memnuniyetini ön planda tutarak
                  hizmetlerimizi sürekli iyileştirmek için geri bildirimlere
                  önem veriyoruz. Kullanıcılarımıza hizmetlerimizi değerlendirme
                  ve yorum yapma imkanı sunarak güvenilir bir platform
                  oluşturuyoruz.
                </p>
              </li>
              <li class='border border-gray-200 rounded-lg p-4'>
                <strong class='text-lg font-semibold mb-2 block'>
                  Mobil Uygulama Desteği:
                </strong>
                <p>
                  Kullanıcılarımıza hizmetlere kolayca erişim sağlamak için özel
                  olarak geliştirilmiş bir mobil uygulama sunuyoruz. Mobil
                  uygulamamız ile hizmetlere hızlıca ulaşabilir, rezervasyon
                  yapabilir ve ödeme işlemlerini güvenle
                  gerçekleştirebilirsiniz.
                </p>
              </li>
              <li class='border border-gray-200 rounded-lg p-4'>
                <strong class='text-lg font-semibold mb-2 block'>
                  Müşteri Destek Hizmetleri:
                </strong>
                <p>
                  Müşteri memnuniyetini her zaman ön planda tutarak 7/24
                  erişilebilir müşteri destek hizmetleri sunuyoruz. Profesyonel
                  ve deneyimli destek ekibimiz ile müşterilerimizin her türlü
                  soru ve sorunlarını çözüme kavuşturuyoruz.
                </p>
              </li>
              <li class='border border-gray-200 rounded-lg p-4'>
                <strong class='text-lg font-semibold mb-2 block'>
                  SEO Desteği:
                </strong>
                <p>
                  Web uygulamanızı arama motorlarında daha görünür hale getirmek
                  için SEO dostu tekniklerle tasarlıyoruz. Böylece potansiyel
                  müşterilerinizin sizi daha kolay bulmasını ve işletmenize
                  ulaşmasını sağlıyoruz.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div
          data-aos-easing='linear'
          data-aos-duration='1000'
          data-aos='fade-right'
          className='w-full mx-12 flex my-32  xl:flex-col xl:my-0'
        >
          <Card
            link={'https://www.ajansnoa.com/'}
            image={'./image/site7.png'}
          />
          <div className='p-12 xl:p-6'>
            <h3 className='text-3xl font-mono my-3'>
              Kurumsal Siteler için neler yapıyoruz
            </h3>
            <ul class='flex flex-col gap-4'>
              <li class='border border-gray-200 rounded-lg p-4'>
                <strong class='text-lg font-semibold mb-2 block'>
                  Hakkımızda Sayfası:
                </strong>
                <p>
                  Şirketin köklü tarihçesi, sağlam vizyonu ve değerlerini
                  yansıtan detaylı bir "Hakkımızda" sayfası. Şeffaflık ve
                  güvenilirlik ilkesiyle hareket ederek müşterilerimize
                  şirketimizi yakından tanıtmayı amaçlıyoruz.
                </p>
              </li>
              <li class='border border-gray-200 rounded-lg p-4'>
                <strong class='text-lg font-semibold mb-2 block'>
                  Ürün ve Hizmetler:
                </strong>
                <p>
                  Şirketimizin sunduğu benzersiz ürünler ve kaliteli hizmetler
                  hakkında detaylı bilgi sunan bir bölüm. Müşterilerimizin
                  ihtiyaçlarına uygun çözümler sunarak, onların beklentilerini
                  aşmayı hedefliyoruz.
                </p>
              </li>
              <li class='border border-gray-200 rounded-lg p-4'>
                <strong class='text-lg font-semibold mb-2 block'>
                  İletişim Bilgileri:
                </strong>
                <p>
                  Müşterilerimizin her zaman bizimle iletişim kurabileceği
                  iletişim bilgileri, iletişim formu veya canlı sohbet gibi
                  etkili iletişim yöntemleri.
                </p>
              </li>
              <li class='border border-gray-200 rounded-lg p-4'>
                <strong class='text-lg font-semibold mb-2 block'>
                  Müşteri Referansları ve Başarı Hikayeleri:
                </strong>
                <p>
                  Şirketimizin daha önceki müşterileriyle yaşadığı başarı
                  hikayeleri ve memnuniyet referansları. Müşterilerimizin
                  deneyimlerini paylaşarak güvenilirliğimizi ve kalitemizi
                  kanıtlıyoruz.
                </p>
              </li>
              <li class='border border-gray-200 rounded-lg p-4'>
                <strong class='text-lg font-semibold mb-2 block'>
                  Blog veya Haberler:
                </strong>
                <p>
                  Sektördeki yenilikleri, şirket haberlerini, başarı öykülerini
                  ve ürün güncellemelerini düzenli olarak paylaştığımız bir blog
                  veya haberler bölümü. Müşterilerimizi bilgilendirmek ve
                  etkilemek için değerli içerikler sunuyoruz.
                </p>
              </li>
              <li class='border border-gray-200 rounded-lg p-4'>
                <strong class='text-lg font-semibold mb-2 block'>
                  SSS (Sıkça Sorulan Sorular):
                </strong>
                <p>
                  Ziyaretçilerimizin sıkça sorduğu soruların cevaplarını içeren
                  bir SSS bölümü. Müşterilerimizin olası endişelerini gidererek
                  karar vermelerini kolaylaştırıyoruz.
                </p>
              </li>
              <li class='border border-gray-200 rounded-lg p-4'>
                <strong class='text-lg font-semibold mb-2 block'>
                  Kariyer:
                </strong>
                <p>
                  Şirketimizin büyümesine katkı sağlayacak yetenekli ve motive
                  çalışanları bünyemize katmak için kariyer olanakları, açık
                  pozisyonlar ve iş başvuru formu gibi kariyer bölümü.
                </p>
              </li>
              <li class='border border-gray-200 rounded-lg p-4'>
                <strong class='text-lg font-semibold mb-2 block'>
                  Gizlilik Politikası ve Kullanım Koşulları:
                </strong>
                <p>
                  Ziyaretçilere şirketimizin gizlilik politikası ve web sitesi
                  kullanım koşulları hakkında şeffaf ve anlaşılır bilgiler sunan
                  sayfalar.
                </p>
              </li>
              <li class='border border-gray-200 rounded-lg p-4'>
                <strong class='text-lg font-semibold mb-2 block'>
                  Mobil Uyumlu Tasarım:
                </strong>
                <p>
                  Web sitesinin her türlü cihazda mükemmel görüntülenmesini
                  sağlayan ve kullanıcı deneyimini optimize eden mobil uyumlu
                  bir tasarım.
                </p>
              </li>
              <li class='border border-gray-200 rounded-lg p-4'>
                <strong class='text-lg font-semibold mb-2 block'>
                  SEO Dostu Tasarım:
                </strong>
                <p>
                  Web sitesinin arama motorlarında en üst sıralarda yer almasını
                  sağlayan SEO uyumlu tasarım ve içerikler.
                </p>
              </li>
              <li class='border border-gray-200 rounded-lg p-4'>
                <strong class='text-lg font-semibold mb-2 block'>
                  Sosyal Medya Entegrasyonu:
                </strong>
                <p>
                  Web sitesinin sosyal medya platformları ile entegre edilmesi
                  ve müşterilerin sosyal medya üzerinden etkileşimde
                  bulunabileceği bir ortamın sağlanması.
                </p>
              </li>
              <li class='border border-gray-200 rounded-lg p-4'>
                <strong class='text-lg font-semibold mb-2 block'>
                  Çevrimiçi Destek ve Canlı Sohbet:
                </strong>
                <p>
                  Ziyaretçilerin web sitesi üzerinden çevrimiçi destek
                  alabilecekleri veya canlı sohbet yapabilecekleri etkili bir
                  destek sistemi.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div
          data-aos='fade-right'
          data-aos-easing='linear'
          data-aos-duration='1000'
          className='w-full mx-12 flex flex-row-reverse my-32  xl:flex-col xl:my-0'
        >
          <Card link={'https://www.tremyy.com/'} image={'./image/site1.png'} />
          <div className='p-12 xl:p-6'>
            <h3 className='text-3xl font-mono my-3'>
              Özel Siteler için neler yapıyoruz
            </h3>
            <div className='flex flex-col gap-4'>
              <ul class='flex flex-col gap-4'>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    İşletmenize Özel Çözümler:
                  </strong>
                  <p>
                    Özel web uygulamalarımız, işletmenizin ihtiyaçlarına tam
                    olarak uyum sağlayacak şekilde özelleştirilir. Size özel
                    olarak tasarlanan çözümlerle rakiplerinizden farklılaşın ve
                    işletmenizin potansiyelini maksimize edin.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Verimlilikte Büyük Artış:
                  </strong>
                  <p>
                    Özel web uygulamalarımız, iş süreçlerinizi optimize ederek
                    verimliliği artırır. Tekrar eden görevleri otomatikleştirir,
                    verimli iş akışları sağlar ve çalışanlarınızın daha fazla
                    verimli olmasını sağlar.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Güvenlikte Maksimum Koruma:
                  </strong>
                  <p>
                    İşletmenizin verileri bizim için en değerli varlıktır. Özel
                    web uygulamalarımız, güçlü güvenlik önlemleriyle
                    donatılmıştır ve hassas verilerinizi her zaman korur.
                    Böylece işletmenizin güvenliğini sağlamak için gereken tüm
                    önlemleri alırız.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Esneklik ve Ölçeklenebilirlik:
                  </strong>
                  <p>
                    İşletmeniz büyüdükçe ve değiştikçe, özel web uygulamalarımız
                    da buna uyum sağlar. Esnek ve ölçeklenebilir bir yapıya
                    sahip olduğumuz için, her zaman ihtiyaçlarınıza uygun
                    çözümler sunarız ve işletmenizin büyümesine destek oluruz.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Sürekli Destek ve Bakım:
                  </strong>
                  <p>
                    Özel web uygulamalarımızı satın aldığınızda, sadece bir ürün
                    değil, bir iş ortağı kazanırsınız. Profesyonel destek
                    ekibimiz her zaman yanınızda olacak ve herhangi bir sorunla
                    karşılaştığınızda hızlıca çözüm sunacaktır. Böylece
                    işletmeniz hiçbir zaman durmaz ve her zaman en iyi
                    performansı gösterir.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    SEO Desteği:
                  </strong>
                  <p>
                    Web uygulamanızı arama motorlarında daha görünür hale
                    getirmek için SEO dostu tekniklerle tasarlıyoruz. Böylece
                    potansiyel müşterilerinizin sizi daha kolay bulmasını ve
                    işletmenize ulaşmasını sağlıyoruz.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Muhasebe Entegrasyonu:
                  </strong>
                  <p>
                    İşletmenizin muhasebe işlemlerini takip etmek için özel bir
                    muhasebe entegrasyonu sunuyoruz. Böylece finansal
                    verilerinizi kolayca yönetebilir, işletmenizin mali durumunu
                    izleyebilir ve büyüme stratejilerinizi belirleyebilirsiniz.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          data-aos-easing='linear'
          data-aos-duration='1000'
          data-aos='fade-right'
          className='w-full mx-12 flex flex-row my-32  xl:flex-col xl:my-0'
        >
          <Card link={'https://fnaksesuar.com//'} image={'./image/site4.png'} />
          <div className='p-12 xl:p-6'>
            <h3 className='text-3xl font-mono my-3'>
              Aksasuar ve Takısiteleri için neler yapıyoruz
            </h3>
            <div className='flex flex-col gap-4'>
              <ul class='flex flex-col gap-4'>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Geniş Ürün Katalogu:
                  </strong>
                  <p>
                    Takı ve aksesuarların zengin bir şekilde listelendiği
                    kapsamlı bir ürün kataloğu. Her ürün için detaylı
                    açıklamalar, fiyatlar, malzemeler, boyutlar ve renk
                    seçenekleri sunulur.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Filtreleme ve Arama Özelliği:
                  </strong>
                  <p>
                    Kullanıcıların kolaylıkla istedikleri ürünleri bulmalarını
                    sağlayan filtreleme ve arama özellikleri. Kullanıcılar
                    kategoriye, fiyata, malzeme türüne ve diğer özelliklere göre
                    arama yapabilir ve filtreleme yapabilir.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Üye Kaydı ve Profil Yönetimi:
                  </strong>
                  <p>
                    Kullanıcıların siteye üye olabileceği, profil bilgilerini
                    yönetebileceği ve sipariş geçmişlerini görüntüleyebileceği
                    bir üye kayıt ve profil yönetim sistemi.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Sepet ve Güvenli Ödeme:
                  </strong>
                  <p>
                    Kullanıcıların sepetlerindeki ürünleri düzenleyebileceği,
                    ödeme işlemlerini güvenli bir şekilde tamamlayabileceği bir
                    alışveriş sepeti ve ödeme sistemi.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Kampanyalar ve İndirimler:
                  </strong>
                  <p>
                    Belirli zamanlarda veya ürünlerde sunulan indirimler,
                    kampanyalar ve promosyonlarla müşteri çekilir ve satışlar
                    artırılır.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Hızlı ve Güvenilir Kargo:
                  </strong>
                  <p>
                    Müşterilerin sipariş ettikleri ürünleri hızlı bir şekilde
                    teslim alabilmeleri için güvenilir kargo şirketleriyle
                    entegre bir kargo yönetim sistemi.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Müşteri İlişkileri Yönetimi (CRM):
                  </strong>
                  <p>
                    Müşterilerin iletişim bilgilerini ve satın alma geçmişlerini
                    yönetebilecekleri bir CRM sistemi. Müşterilere özel
                    promosyonlar ve indirimler sunulabilir.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Ürün İnceleme ve Değerlendirme:
                  </strong>
                  <p>
                    Müşterilerin satın aldıkları ürünleri inceleyip
                    değerlendirebilecekleri bir inceleme ve değerlendirme
                    sistemi. Bu, diğer müşterilerin satın alma kararlarını
                    desteklemeye yardımcı olur.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Trendler ve Stil İpuçları:
                  </strong>
                  <p>
                    Kullanıcılara takı trendleri, moda ipuçları ve stil
                    önerileri sunarak alışveriş deneyimini zenginleştiren bir
                    içerik bölümü.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Mobil Uyumlu Tasarım:
                  </strong>
                  <p>
                    Web sitesinin mobil cihazlarda düzgün görüntülenmesini
                    sağlayan ve mobil kullanıcı deneyimini optimize eden bir
                    tasarım. Bu, mobil alışverişin artması ve müşteri
                    memnuniyetinin sağlanması için önemlidir.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    SEO Desteği:
                  </strong>
                  <p>
                    Web sitemizin arama motorlarında daha iyi performans
                    göstermesini sağlamak için SEO (Arama Motoru Optimizasyonu)
                    desteği sunuyoruz. Böylece potansiyel müşterilerin sizi daha
                    kolay bulmasını sağlıyoruz.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          data-aos='fade-right'
          data-aos-easing='linear'
          data-aos-duration='1000'
          className='w-full mx-12 flex flex-row-reverse my-32  xl:flex-col xl:my-0'
        >
          <Card
            link={'https://demo4.renamesoft.com/index.php'}
            image={'./image/site13.png'}
          />
          <div className='p-12 xl:p-6'>
            <h3 className='text-3xl font-mono my-3'>
              Avukatlar için neler yapıyoruz
            </h3>
            <div className='flex flex-col gap-4'>
              <ul class='flex flex-col gap-4'>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Hukuki Danışmanlık:
                  </strong>
                  <p>
                    Müşterilere çeşitli hukuki konularda danışmanlık hizmeti
                    sağlanır. Bu, kanunlar, düzenlemeler ve davalarla ilgili
                    profesyonel tavsiyeleri içerir.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Dava Takibi ve Temsil:
                  </strong>
                  <p>
                    Müvekkillerin dava süreçlerini takip etmek ve onları yasal
                    olarak temsil etmek. Bu, mahkeme duruşmalarına katılımı,
                    dilekçe hazırlanması ve savunma sunulmasını içerebilir.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Sözleşme Hazırlama:
                  </strong>
                  <p>
                    Müşterilerin çeşitli sözleşmeleri hazırlamalarına ve
                    incelerine yardımcı olmak. Bu, iş sözleşmeleri, kira
                    sözleşmeleri ve diğer hukuki belgeleri kapsar.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Miras ve Miras Hukuku:
                  </strong>
                  <p>
                    Müşterilerin miras ve miras hukuku konularında bilgi alması
                    ve danışmanlık hizmeti alması. Bu, mirasın paylaşımı,
                    vasiyetnamelerin hazırlanması ve miras davalarının takibi
                    gibi konuları içerebilir.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Şirket Hukuku:
                  </strong>
                  <p>
                    Şirketlerin hukuki gereksinimleri hakkında danışmanlık
                    hizmeti sağlamak. Bu, şirket kuruluşu, anlaşmaların
                    hazırlanması ve şirketler arası anlaşmazlıkların çözülmesi
                    gibi konuları içerir.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Kişisel Yaralanma Davaları:
                  </strong>
                  <p>
                    Müşterilerin kişisel yaralanma davalarıyla ilgili hukuki
                    temsil ve danışmanlık hizmeti alması. Bu, trafik kazaları,
                    iş kazaları ve tıbbi hatalar gibi durumları kapsar.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Gizlilik ve Güvenlik:
                  </strong>
                  <p>
                    Müşterilerin kişisel ve işletme bilgilerinin gizliliğini
                    korumak için danışmanlık sağlamak. Bu, veri koruma
                    düzenlemeleri, gizlilik politikaları ve bilgi güvenliği
                    önlemlerini içerir.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Hukuki Araştırma ve Analiz:
                  </strong>
                  <p>
                    Müşterilerin hukuki konularında araştırma yapmak, kanunlara
                    göre analizler sunmak ve olası sonuçları değerlendirmek.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Sözleşmelerin İzlenmesi ve Uyarlanması:
                  </strong>
                  <p>
                    Müşterilerin var olan sözleşmeleri izlemesine ve
                    gerektiğinde uygun değişiklikler yapmasına yardımcı olmak.
                    Bu, sözleşme ihlallerinin önlenmesini ve sorunların
                    çözülmesini içerir.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    İtiraz ve Temyiz:
                  </strong>
                  <p>
                    Müşterilerin hukuki kararlara itiraz etmesine ve temyiz
                    süreçlerini yürütmesine yardımcı olmak. Bu, mahkeme
                    kararlarına karşı dava açma ve yargıtay süreçlerini
                    yönetmeyi içerebilir.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    SEO Desteği:
                  </strong>
                  <p>
                    Web sitemizin arama motorlarında daha iyi performans
                    göstermesini sağlamak için SEO (Arama Motoru Optimizasyonu)
                    desteği sunuyoruz. Böylece potansiyel müşterilerin sizi daha
                    kolay bulmasını sağlıyoruz.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Mobil Uyum:
                  </strong>
                  <p>
                    Web sitemiz, mobil cihazlarda sorunsuz çalışacak şekilde
                    tasarlanmıştır. Böylece istediğiniz her yerden kolayca araç
                    kiralayabilirsiniz.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          data-aos-easing='linear'
          data-aos-duration='1000'
          data-aos='fade-right'
          className='w-full mx-12 flex flex-row my-32  xl:flex-col xl:my-0'
        >
          <Card
            link={'https://demo2.renamesoft.com/index.php'}
            image={'./image/site8.png'}
          />
          <div className='p-12 xl:p-6'>
            <h3 className='text-3xl font-mono my-3'>
              Rentekar Ve Araç Kiralama Firmaları için neler yapıyoruz
            </h3>
            <div className='flex flex-col gap-4'>
              <ul class='flex flex-col gap-4'>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Geniş Araç Seçenekleri:
                  </strong>
                  <p>
                    Müşterilere farklı ihtiyaçlarına ve tercihlerine uygun geniş
                    bir araç yelpazesi sunuyoruz. Lüks araçlardan ekonomik
                    modellere kadar her türden aracı bulabileceğinizden emin
                    olabilirsiniz.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Kullanıcı Dostu Arama ve Filtreleme:
                  </strong>
                  <p>
                    Ihtiyaçlarınıza uygun aracı bulmak hiç bu kadar kolay
                    olmamıştı. Web sitemizdeki kullanıcı dostu arama ve
                    filtreleme özellikleri sayesinde istediğiniz özelliklere
                    sahip araçları hızlıca bulabilirsiniz.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Kolay Rezervasyon Süreci:
                  </strong>
                  <p>
                    Araç kiralamak artık birkaç tıklama kadar kolay. Sadece
                    tercih ettiğiniz tarih aralığını seçin, istediğiniz aracı
                    seçin ve rezervasyonunuzu tamamlayın. Hızlı, güvenilir ve
                    kullanıcı dostu bir rezervasyon deneyimi için buradayız.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Esnek Fiyatlandırma Seçenekleri:
                  </strong>
                  <p>
                    Günlük, haftalık veya aylık olarak esnek fiyatlandırma
                    seçenekleri sunarak bütçenize uygun bir seçenek bulmanıza
                    yardımcı oluyoruz. Siz isteyin, biz size en uygun fiyatı
                    sunalım.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Profesyonel Müşteri Desteği:
                  </strong>
                  <p>
                    Müşteri memnuniyeti bizim için en öncelikli konudur.
                    Profesyonel ve deneyimli müşteri destek ekibimiz, her türlü
                    sorunuz veya sorunuz için hızlı ve etkili çözümler sunmaya
                    hazırdır. Siz sorunuz, biz cevaplayalım.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Kolay İade ve Teslimat:
                  </strong>
                  <p>
                    Araçlarımızı teslim alabileceğiniz ve iade edebileceğiniz
                    çeşitli lokasyonlar sunuyoruz. Havaalanı, otel veya şehir
                    merkezi gibi farklı noktalardan araçlarınızı alabilir veya
                    iade edebilirsiniz. Size uygun olanı seçin, gerisini biz
                    halledelim.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    SEO Desteği:
                  </strong>
                  <p>
                    Web sitemizin arama motorlarında daha iyi performans
                    göstermesini sağlamak için SEO (Arama Motoru Optimizasyonu)
                    desteği sunuyoruz. Böylece potansiyel müşterilerin sizi daha
                    kolay bulmasını sağlıyoruz.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Mobil Uyum:
                  </strong>
                  <p>
                    Web sitemiz, mobil cihazlarda sorunsuz çalışacak şekilde
                    tasarlanmıştır. Böylece istediğiniz her yerden kolayca araç
                    kiralayabilirsiniz.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          data-aos='fade-right'
          data-aos-easing='linear'
          data-aos-duration='1000'
          className='w-full mx-12 flex flex-row-reverse my-32  xl:flex-col xl:my-0'
        >
          <Card
            link={'https://demo1.renamesoft.com/index.php'}
            image={'./image/site10.png'}
          />
          <div className='p-12 xl:p-6'>
            <h3 className='text-3xl font-mono my-3'>
              Oteller için neler yapıyoruz
            </h3>
            <div className='flex flex-col gap-4'>
              <ul class='flex flex-col gap-4'>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Oda Arama ve Filtreleme:
                  </strong>
                  <p>
                    Misafirlerimizin beklentilerine uygun odaları kolayca arayıp
                    bulabilecekleri bir oda arama ve filtreleme sistemi. Bu
                    sistem, oda tipi, fiyat, tarih aralığı ve diğer özelliklere
                    göre odaları filtreleme imkanı sağlar.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Oda Detayları:
                  </strong>
                  <p>
                    Her odanın ayrıntılı bilgilerinin bulunduğu özenle
                    hazırlanmış bir oda detay sayfası. Bu sayfada oda
                    fotoğrafları, oda tipi, yatak sayısı, fiyatlandırma
                    detayları, özellikler ve diğer detaylar yer alır.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Rezervasyon Sistemi:
                  </strong>
                  <p>
                    Misafirlerimizin istedikleri tarih aralığını seçip kolayca
                    rezervasyon yapabilecekleri güvenilir bir rezervasyon
                    sistemi. Hızlı ve sorunsuz rezervasyon işlemleri için
                    gelişmiş bir rezervasyon formu sunuyoruz.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Fiyatlandırma ve Ödeme:
                  </strong>
                  <p>
                    Odaların günlük veya toplam fiyatlarının şeffaf bir şekilde
                    sunulduğu bir fiyat listesi. Misafirlerimiz
                    rezervasyonlarını tamamlamak için güvenli ödeme
                    yöntemleriyle kolayca ödeme yapabilirler.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Otel Hizmetleri:
                  </strong>
                  <p>
                    Otelimizin sunduğu eşsiz hizmetlerin ve olanakların detaylı
                    bir şekilde anlatıldığı bir bölüm. Restoranlarımız, spa
                    merkezimiz, havuzlarımız ve daha fazlasıyla unutulmaz bir
                    konaklama deneyimi sunuyoruz.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Yerel İlgi Noktaları:
                  </strong>
                  <p>
                    Otelimizin bulunduğu bölgedeki turistik mekanlar ve ilgi
                    çekici noktalar hakkında kapsamlı bilgiler. Misafirlerimize
                    otelimizin çevresindeki keşfedilmeyi bekleyen güzellikleri
                    gösteriyoruz.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Müşteri İncelemeleri:
                  </strong>
                  <p>
                    Otelimizde konaklamış misafirlerimizin samimi yorumlarını ve
                    deneyimlerini içeren gerçek müşteri incelemeleri bölümü. Siz
                    de diğer misafirlerimizin deneyimlerine göz atarak bize olan
                    güveninizi pekiştirebilirsiniz.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    İletişim Bilgileri:
                  </strong>
                  <p>
                    Bizimle iletişime geçmek için gerekli tüm iletişim
                    bilgilerinin bulunduğu bir sayfa. Sorularınızı yanıtlamaktan
                    ve sizi ağırlamaktan mutluluk duyarız. Bize ulaşın ve
                    unutulmaz bir konaklama için adım atın.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Mobil Uyumlu Tasarım:
                  </strong>
                  <p>
                    Web sitemizin mobil cihazlarda sorunsuz bir şekilde
                    görüntülenmesini sağlayan modern bir tasarım. Her zaman ve
                    her yerden bize erişebilmeniz için mobil uyumlu bir deneyim
                    sunuyoruz.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    SEO Desteği:
                  </strong>
                  <p>
                    Web uygulamanızı arama motorlarında daha görünür hale
                    getirmek için SEO dostu tekniklerle tasarlıyoruz. Böylece
                    potansiyel müşterilerinizin sizi daha kolay bulmasını ve
                    işletmenize ulaşmasını sağlıyoruz.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          data-aos-easing='linear'
          data-aos-duration='1000'
          data-aos='fade-right'
          className='w-full mx-12 flex flex-row my-32  xl:flex-col xl:my-0'
        >
          <Card
            link={'https://demo5.renamesoft.com/index.php'}
            image={'./image/site14.png'}
          />
          <div className='p-12 xl:p-6'>
            <h3 className='text-3xl font-mono my-3'>
              Kamera Sistemleri Mağazalar için neler yapıyoruz
            </h3>
            <div className='flex flex-col gap-4'>
              <ul class='flex flex-col gap-4'>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Geniş Kamera Seçenekleri:
                  </strong>
                  <p>
                    Farklı ihtiyaçlara ve tercihlere uygun geniş bir kamera
                    yelpazesi sunuyoruz. Amatör fotoğrafçılardan profesyonellere
                    kadar her türden kamerayı bulabileceğinizden emin
                    olabilirsiniz.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Kullanıcı Dostu Arama ve Filtreleme:
                  </strong>
                  <p>
                    İhtiyacınıza uygun kamerayı bulmak hiç bu kadar kolay
                    olmamıştı. Web sitemizdeki kullanıcı dostu arama ve
                    filtreleme özellikleri sayesinde istediğiniz özelliklere
                    sahip kameraları hızlıca bulabilirsiniz.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Kolay Satın Alma Süreci:
                  </strong>
                  <p>
                    Kamera satın almak artık birkaç tıklama kadar kolay. Sadece
                    istediğiniz modeli seçin, ödemenizi yapın ve kameranızı
                    hızlıca teslim alın. Hızlı, güvenilir ve kullanıcı dostu bir
                    alışveriş deneyimi için buradayız.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Esnek Fiyatlandırma Seçenekleri:
                  </strong>
                  <p>
                    Günlük, haftalık veya aylık olarak esnek fiyatlandırma
                    seçenekleri sunarak bütçenize uygun bir seçenek bulmanıza
                    yardımcı oluyoruz. Siz isteyin, biz size en uygun fiyatı
                    sunalım.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Profesyonel Müşteri Desteği:
                  </strong>
                  <p>
                    Müşteri memnuniyeti bizim için en önemli konudur.
                    Profesyonel ve deneyimli müşteri destek ekibimiz, her türlü
                    sorunuz veya sorunuz için hızlı ve etkili çözümler sunmaya
                    hazırdır. Siz sorunuz, biz cevaplayalım.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Kolay İade ve Değişim:
                  </strong>
                  <p>
                    Kameralarımızı satın aldıktan sonra memnun kalmamanız
                    durumunda kolay iade ve değişim imkanı sunuyoruz. Size uygun
                    olanı seçin, gerisini biz halledelim.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    SEO Desteği:
                  </strong>
                  <p>
                    Web sitemizin arama motorlarında daha iyi performans
                    göstermesini sağlamak için SEO (Arama Motoru Optimizasyonu)
                    desteği sunuyoruz. Böylece potansiyel müşterilerin sizi daha
                    kolay bulmasını sağlıyoruz.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Mobil Uyum:
                  </strong>
                  <p>
                    Web sitemiz, mobil cihazlarda sorunsuz çalışacak şekilde
                    tasarlanmıştır. Böylece istediğiniz her yerden kolayca
                    kameralarınızı inceleyebilirsiniz.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          data-aos='fade-right'
          data-aos-easing='linear'
          data-aos-duration='1000'
          className='w-full mx-12 flex flex-row-reverse my-32  xl:flex-col xl:my-0'
        >
          <Card
            link={'https://demo6.renamesoft.com/index.php'}
            image={'./image/site15.png'}
          />
          <div className='p-12 xl:p-6'>
            <h3 className='text-3xl font-mono my-3'>
              Oteller için neler yapıyoruz
            </h3>
            <div className='flex flex-col gap-4'>
              <ul class='flex flex-col gap-4'>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Geniş Hizmet Yelpazesi:
                  </strong>
                  <p>
                    Araç tamirinde uzmanlaşmış bir ekibimizle farklı ihtiyaçlara
                    uygun geniş bir hizmet yelpazesi sunuyoruz. Motor, şanzıman,
                    fren sistemleri, elektrik ve diğer birçok alanda tamir ve
                    bakım hizmetleri sunmaktayız.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Hızlı ve Güvenilir Onarım:
                  </strong>
                  <p>
                    Aracınızın onarımı için bize teslim ettiğinizde, işimizi
                    hızlı ve güvenilir bir şekilde yapmak için elimizden geleni
                    yapıyoruz. Uzman teknisyenlerimiz aracınızı en kısa sürede
                    tamir ederek size güvenli bir sürüş deneyimi sunarlar.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Uygun Fiyatlandırma:
                  </strong>
                  <p>
                    Araç tamir ve bakımında rekabetçi fiyatlar sunarak
                    müşterilerimize bütçe dostu seçenekler sunuyoruz. Kaliteli
                    hizmeti uygun fiyatlarla almanızı sağlamak için çaba
                    gösteriyoruz.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Profesyonel Müşteri Desteği:
                  </strong>
                  <p>
                    Müşteri memnuniyeti bizim için en önemli konudur. Deneyimli
                    müşteri destek ekibimiz, her türlü sorunuz veya sorunuz için
                    hızlı ve etkili çözümler sunmaya hazırdır. Siz sorunuz, biz
                    cevaplayalım.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Güvenlik Garantisi:
                  </strong>
                  <p>
                    Aracınızı bize teslim ettiğinizde, güvenliği en üst düzeyde
                    sağlamak için gereken tüm önlemleri alıyoruz. Tamirat
                    sonrasında aracınızın güvenli bir şekilde kullanılabilir
                    durumda olduğundan emin olabilirsiniz.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    SEO Desteği:
                  </strong>
                  <p>
                    Web sitemizin arama motorlarında daha iyi performans
                    göstermesini sağlamak için SEO (Arama Motoru Optimizasyonu)
                    desteği sunuyoruz. Böylece potansiyel müşterilerin sizi daha
                    kolay bulmasını sağlıyoruz.
                  </p>
                </li>
                <li class='border border-gray-200 rounded-lg p-4'>
                  <strong class='text-lg font-semibold mb-2 block'>
                    Mobil Uyum:
                  </strong>
                  <p>
                    Web sitemiz, mobil cihazlarda sorunsuz çalışacak şekilde
                    tasarlanmıştır. Böylece istediğiniz her yerden kolayca
                    hizmetlerimizi inceleyebilir ve iletişime geçebilirsiniz.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Footer />
        <Destek />
      </div>
    </main>
  )
}

export default Home
