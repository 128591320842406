import React, { useEffect, useState } from "react";
import Navbar from "../../components/common/header/Navbar";
import Footer from "../../components/common/footer/Footer";
import { useParams } from "react-router-dom";

const CategoryDetay = ({ sepet, setSepet }) => {
  const { name } = useParams();
  const [showData, setShowData] = useState(null);
  const data = [
    {
      name: "mobil",
      img: "/image/mobil_cat.jpg",
      tite: "Geleceği Şekillendiren Kod Parçacıkları: Yenilikçi Mobil Uygulama Geliştirme Hizmetleri",
      description1: `Teknolojinin hızla ilerlediği bir çağda, işletmelerin rekabetçi kalabilmeleri için dijital varlıkları, özellikle de mobil uygulamaları, önemli birer araç haline geldi. RenameSoft, bu dijital dönüşümde öncü bir rol üstlenerek, yenilikçi mobil uygulama geliştirme hizmetleriyle sektörde öne çıkıyor.
      `,
      tite2: "Vizyon ve Yaklaşım:",
      description2: `Biz, teknolojiyi insanlar için daha kullanılabilir ve erişilebilir hale getirme misyonuyla yola çıktık. Müşterilerimizin ihtiyaçlarına odaklanarak, kullanıcı deneyimini merkeze alan ve işlevselliği ön planda tutan mobil uygulamalar geliştiriyoruz. Tasarladığımız her bir kod parçacığı, müşterilerimizin ve son kullanıcıların gereksinimlerini karşılamak üzere titizlikle işleniyor.`,
      tite3: "Mobil Uygulama Geliştirme Hizmetleri:",
      description3: `Stratejik Danışmanlık: İhtiyaç analizi, pazar araştırması ve rekabet analizi yaparak müşterilerimize özelleştirilmiş çözümler sunuyoruz.
      Mobil Uygulama Geliştirme: iOS, Android ve çapraz platformlar için kullanıcı dostu, işlevsel ve estetik uygulamalar üretiyoruz.
      Test ve Kalite Güvencesi: Uygulamalarımızı titizlikle test ederek en üst düzeyde performans ve güvenlik sağlıyoruz.
      Sürekli Destek ve Güncelleme: Uygulamaların ömrü boyunca sürekli güncellenmesi ve teknik destek sunarak müşterilerimizin yanında oluyoruz.`,
      title4: "Farklılık Yaratan Özelliklerimiz:",
      description4: `Kullanıcı Odaklı Tasarım: Her aşamada kullanıcıyı merkeze alan, sezgisel ve estetik bir tasarım anlayışı benimsiyoruz.
      Yenilikçi Teknolojiler: En son teknolojileri takip ederek, uygulamalarımızda en verimli ve güncel araçları kullanıyoruz.
      Esnek ve Hızlı İterasyonlar: Hızlı değişime adapte olabilme yeteneğimiz sayesinde müşteri geri bildirimlerini anında değerlendirerek uygulamaları sürekli iyileştiriyoruz.`,
    },
    {
      name: "restorant",
      img: "/image/mobil_cat.jpg",
      tite: "Lezzetli Deneyimlerin Arkasındaki Dijital Dokunuş: Restoran Yönetim Sistemleri",
      description1: `Gastronomi dünyası, lezzetli yemeklerin yanı sıra verimli işletme yönetimini de gerektiriyor. RenameSoft, restoranlar için özelleştirilmiş ve etkili çözümler sunarak, hem lezzet hem de işletme verimliliği konusunda fark yaratıyor.
        `,
      tite2: "Vizyon ve Yaklaşım:",
      description2: `Biz, restoranların dijital dönüşümünde öncü bir rol üstleniyoruz. Müşterilerimizin gereksinimlerini anlamak ve onların ihtiyaçlarına yönelik çözümler üretmek için yoğun bir çaba harcıyoruz. Restoranlar için kullanımı kolay, yenilikçi ve verimlilik odaklı yönetim sistemleri geliştiriyoruz.`,
      tite3: "Hizmetlerimiz:",
      description3: `Sipariş Yönetimi: Masa siparişleri, online siparişler, teslimat yönetimi ve stok takibi gibi süreçleri entegre ederek restoran işletmeciliğini kolaylaştırıyoruz.
      Müşteri Deneyimi: Müşteri sadakatini artırmak için özelleştirilmiş sadakat programları, geri bildirim sistemleri ve müşteri ilişkileri yönetimi sunuyoruz.
      Envanter Yönetimi: Stok takibi, tedarik yönetimi ve otomatik yenileme gibi işlemleri optimize ederek maliyetleri düşürüyoruz.
      Raporlama ve Analiz: Verileri anlamlı bilgilere dönüştürerek, işletmecilere stratejik karar alma süreçlerinde yardımcı oluyoruz.`,
      title4: "Farklılık Yaratan Özelliklerimiz:",
      description4: `Kolay Entegrasyon: Mevcut sistemlere kolayca entegre olabilme yeteneği sayesinde restoran işletmelerinin dijital dönüşüm süreçlerini sorunsuz hale getiriyoruz.
      Mobil Uyumlu Çözümler: Mobil cihazlarla uyumlu ve kullanıcı dostu arayüzler sayesinde restoran yönetimini her yerden kolayca takip etme imkanı sağlıyoruz.
      Güvenilirlik ve Destek: 7/24 teknik destek hizmetleriyle işletmelerin her an yanında oluyoruz ve sistemlerimizin güvenilirliğini sağlamak için sürekli çalışıyoruz.`,
    },
    {
      name: "randevu",
      img: "/image/mobil_cat.jpg",
      tite: "Zamanı Etkin Kullanmanın Anahtarı: Özelleştirilmiş Randevu Yönetim Sistemleri",
      description1: `Zamanın en değerli varlık olduğu bir çağda, işletmelerin verimliliği artırmak ve müşteri memnuniyetini yükseltmek için zamanı doğru yönetmesi kritik bir önem taşıyor. RenameSoft, randevu sistemleriyle işletmelerin zaman yönetimini optimize etmelerine yardımcı oluyor.
        `,
      tite2: "Vizyon ve Yaklaşım:",
      description2: `Biz, işletmelerin verimliliğini artırmak için özelleştirilmiş randevu yönetim sistemleri geliştiriyoruz. Müşterilerimizin ihtiyaçlarını anlamak ve bu ihtiyaçlara yönelik esnek, kullanıcı dostu ve verimli çözümler sunmak için çalışıyoruz.`,
      tite3: "Hizmetlerimiz:",
      description3: `Randevu Planlama: Müşteri randevularını organize etmek ve takip etmek için esnek ve kişiselleştirilebilir sistemler sunuyoruz.
      Müşteri İletişimi: Otomatik hatırlatmalar, geri bildirim alma mekanizmaları ve kişiselleştirilmiş iletişim araçlarıyla müşteri memnuniyetini artırıyoruz.
      Personel Yönetimi: Personel randevularını yönetmek, performansı izlemek ve iş akışını optimize etmek için araçlar sağlıyoruz.
      Raporlama ve Analiz: İşletme sahiplerine veri odaklı kararlar almaları için detaylı raporlama ve analiz imkanları sunuyoruz.`,
      title4: "Farklılık Yaratan Özelliklerimiz:",
      description4: `Esnek Planlama: Müşteri ihtiyaçlarına uygun olarak esnek planlama imkanı sunuyoruz, bu da işletmelerin özelleştirilmiş randevu programları oluşturmasına olanak tanıyor.
      Kullanıcı Dostu Arayüz: Hem işletme sahipleri hem de müşteriler için kolay kullanılabilir, sezgisel bir arayüz sunarak kullanıcı deneyimini ön planda tutuyoruz.
      Güvenilirlik ve Veri Güvenliği: Veri güvenliği standartlarını yüksek tutarak işletmelerin müşteri verilerini güvenle yönetmelerini sağlıyoruz.`,
    },
    {
      name: "chat",
      img: "/image/mobil_cat.jpg",
      tite: "Anlık Bağlantıların Gücü: Canlı Yazışma Uygulamalarında Öncü Çözümler",
      description1: `Dijital dünya, iletişimi her zamankinden daha anlık ve etkileşimli hale getiriyor. RenameSoft, kullanıcıların anlık iletişim ihtiyaçlarını karşılamak ve etkileşimi güçlendirmek için öncü canlı yazışma uygulamaları geliştiriyor.
        `,
      tite2: "Vizyon ve Yaklaşım:",
      description2: `Biz, insanların birbirleriyle anında bağlantı kurabilmelerini ve etkileşimde bulunabilmelerini sağlamak için çalışıyoruz. Kullanıcı odaklı, güvenli ve yüksek performanslı canlı yazışma uygulamaları tasarlayarak iletişimi sınırların ötesine taşıyoruz.`,
      tite3: "Hizmetlerimiz:",
      description3: `Anlık Mesajlaşma: Kullanıcıların hızlı, güvenilir ve şifrelenmiş bir şekilde birbirleriyle iletişim kurmasını sağlıyoruz.
      Grup Sohbetleri: Büyük grupların kolayca iletişim kurabilmesi ve paylaşımda bulunabilmesi için grup sohbetleri sunuyoruz.
      Görüntülü ve Sesli Aramalar: Canlı görüntülü ve sesli arama özellikleriyle kullanıcıların daha zengin iletişim deneyimleri yaşamalarını sağlıyoruz.
      Özelleştirilebilir Arayüzler: Kullanıcıların kendi tercihlerine göre uygulamayı kişiselleştirebilmeleri için esnek arayüz seçenekleri sunuyoruz.`,
      title4: "Farklılık Yaratan Özelliklerimiz:",
      description4: `Gelişmiş Güvenlik: Veri şifreleme, güvenlik duvarları ve kimlik doğrulama gibi önlemlerle kullanıcı verilerinin güvenliğini sağlıyoruz.
      Kullanıcı Dostu Deneyim: Kolay erişilebilirlik, hızlı tepki süreleri ve sezgisel arayüzlerle kullanıcıların en iyi deneyimi yaşamalarını sağlıyoruz.
      Esnek Entegrasyon: Farklı platformlara ve cihazlara kolayca entegre olabilme yeteneğiyle kullanıcıların sorunsuz bir deneyim yaşamasını sağlıyoruz.`,
    },
    {
      name: "eticaret",
      img: "/image/mobil_cat.jpg",
      tite: "Sınırsız İmkânlar, Sınırsız E-Ticaret Çözümleri",
      description1: `Dijital alışveriş dünyası, tüketicilerin taleplerine hızlı ve özelleştirilmiş yanıtlar sunmayı gerektiriyor. RenameSoft, e-ticaret platformlarında şaşırtıcı bir deneyim sunarak işletmelerin dijital dünyada öne çıkmasını sağlıyor.
        `,
      tite2: "Vizyon ve Yaklaşım:",
      description2: `Biz, işletmelerin dijital alanda büyümesine olanak tanıyacak esnek, güvenilir ve ölçeklenebilir e-ticaret çözümleri sunmayı hedefliyoruz. Müşteri odaklı yaklaşımımızla, e-ticaret deneyimini zenginleştiren ve işletmelerin potansiyelini artıran bir ortak olmayı amaçlıyoruz.`,
      tite3: "Hizmetlerimiz:",
      description3: `E-Ticaret Platformları: Özelleştirilebilir ve kullanıcı dostu e-ticaret platformları sunarak işletmelerin çevrimiçi satışlarını optimize etmelerine yardımcı oluyoruz.
      Ödeme ve Güvenlik: Güvenli ödeme sistemleri ve SSL sertifikalarıyla müşteri güvenini artırarak alışveriş deneyimini iyileştiriyoruz.
      Pazarlama ve Entegrasyon: Sosyal medya entegrasyonları, pazarlama kampanyaları ve SEO stratejileriyle işletmelerin çevrimiçi varlığını güçlendiriyoruz.
      Veri Analizi ve Raporlama: Satış verilerini analiz ederek işletmelerin stratejik kararlar almasına yardımcı oluyoruz.`,
      title4: "Özelliklerimiz:",
      description4: `Özelleştirilebilir Platformlar: Her işletmenin ihtiyaçlarına uygun esneklikte ve özelleştirilebilir e-ticaret çözümleri sunuyoruz.
      Mobil Uyum: Mobil cihazlara uyumlu platformlarla, kullanıcıların istedikleri her an ve her yerden alışveriş yapmalarını sağlıyoruz.
      24/7 Destek: Sürekli destek hizmetiyle müşterilerin her zaman yanında oluyoruz ve sorunları anında çözüme kavuşturuyoruz.`,
    },
    {
      name: "otel",
      img: "/image/mobil_cat.jpg",
      tite: "Misafirlerinize Unutulmaz Konaklama Deneyimi Sunan Oteller İçin Öncü Çözümler",
      description1: `
      Elbette, otel yönetim sistemleri sunan bir şirketin tanıtımını içeren bir örnek metin aşağıda yer alıyor:
      
      Başlık: "Misafirlerinize Unutulmaz Konaklama Deneyimi Sunan Oteller İçin Öncü Çözümler"
      
      Giriş:
      Konukseverlik sektörü, misafir memnuniyeti ve işletme verimliliği açısından karmaşık bir yapıya sahiptir. RenameSoft, otellerin konaklama süreçlerini optimize eden ve misafir memnuniyetini artıran yenilikçi otel yönetim sistemleri sunmaktadır.
        `,
      tite2: "Vizyon ve Yaklaşım:",
      description2: `Biz, otel endüstrisinde dijital dönüşümü destekleyen ve misafir odaklı hizmetler sunan bir ekibiz. Misafirlerin ihtiyaçlarına öncelik veren ve işletmelerin operasyonlarını kolaylaştıran çözümler geliştiriyoruz.`,
      tite3: "Hizmetlerimiz:",
      description3: `Otel Yönetim Yazılımları: Rezervasyon yönetimi, oda takibi, faturalandırma ve müşteri ilişkileri yönetimi gibi otel operasyonlarını yöneten kapsamlı yazılımlar sunuyoruz.
      Rezervasyon ve Online Satış: Çeşitli online platformlarda otel rezervasyonları ve pazarlama stratejileri oluşturarak otellerin erişilebilirliğini artırıyoruz.
      Oda Kontrol Sistemleri: Otellerin akıllı oda sistemleri, anahtar kartı yönetimi ve enerji tasarrufu için otomasyon çözümleri sunuyoruz.
      Raporlama ve Veri Analizi: Misafir verilerini analiz ederek işletmelere stratejik kararlar alabilme imkanı sağlıyoruz.`,
      title4: "Özelliklerimiz:",
      description4: `Mükemmel Müşteri Deneyimi: Misafirlerin taleplerine hızlı ve etkili yanıtlar veren, kişiselleştirilmiş hizmetler sunan sistemler geliştiriyoruz.
      Entegrasyon Kolaylığı: Mevcut sistemlere kolayca entegre olabilen, esnek ve ölçeklenebilir çözümler sunuyoruz.
      Güvenlik ve Veri Koruma: Veri güvenliği ve misafir gizliliği konusunda en üst düzeyde önlemler alarak otellerin güvenliğini sağlıyoruz.`,
    },
  ];

  useEffect(() => {
    setShowData(data.filter((item) => item.name === name)[0]);
  });
  return (
    <main>
      <Navbar setSepet={setSepet} sepet={sepet} />
      <section className="absolute top-24  w-full flex flex-col justify-center items-center ">
        <div className="lg:w-3/5 w-full min-h-screen border flex flex-col p-10  gap-10">
          <div className="w-full ">
            <img src={showData?.img} alt="" className="object-fill mx-auto " />
          </div>
          <div className="w-full">
            <div className="text-center text-3xl font-bold">{}</div>
            <div className="flex flex-wrap">
              <div className="w-full flex flex-col gap-5 lg:text-center ">
                <h5 className="text-center text-lg font-semibold">
                  {showData?.tite}
                </h5>
                <p>{showData?.description1}</p>
                <h5 className="text-center text-lg font-semibold">
                  {showData?.tite2}
                </h5>

                <div>{showData?.description2}</div>
                <h5 className="text-center text-lg font-semibold">
                  {showData?.tite3}
                </h5>
                <div>{showData?.description3}</div>

                <h5 className="text-center text-lg font-semibold">
                  {showData?.title4}
                </h5>
                <div>{showData?.description4}</div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </main>
  );
};

export default CategoryDetay;
