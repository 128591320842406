import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { IoIosInfinite } from "react-icons/io";
const Price = () => {
  const [data, setData] = useState(null);
  const fetchPrice = async () => {
    try {
      const response = await fetch(`https://renamesoft.com/r_api/api.php`, {
        method: "POST",
        body: JSON.stringify({
          q: "GET_PACKET",
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();

      if (data.data) {
        setData(data.data);
      }

      if (data.error) {
        console.error("Sunucu hatası:", data.error);
      }
    } catch (err) {
      console.error("Bir hata oluştu:", err);
    }
  };
  let formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  useEffect(() => {
    fetchPrice();
  }, []);

  return (
    <section className="w-full  mb-20 lg:mb-0 flex flex-col justify-center items-center">
      <div className="flex flex-wrap lg:max-w-7xl  md:max-w-4xl  w-full py-10 gap-5   justify-center items-center ">
        {data?.map((item, index) => (
          <div
            key={index}
            className=" min-h-[550px] border font-mono w-full md:w-1/3 lg:w-1/4 py-10 px-3 shadow-md shadow-purple-400  rounded-2xl flex flex-col justify-between items-center "
          >
            <h3 className="text-xl font-bold italic ">{item.packet.title}</h3>
            <div>
              <ul className="list-disc list-inside px-5">
                {item.feature.map((element, index) => (
                  <li key={index} className="text-md">
                    {element.feature}
                  </li>
                ))}
              </ul>
            </div>
            <div className="h-1/3 w-full flex gap-3 flex-col justify-center items-center ">
              {item.packet.price === "0" ? (
                <NavLink
                  to={"/iletisim"}
                  className="h-12 w-2/3 border rounded-xl bg-blue-400 text-white flex justify-center items-center "
                >
                  <span>İletişime Geç</span>
                </NavLink>
              ) : (
                <NavLink
                  to={item.packet.paymet_link}
                  className="h-12 w-2/3 border rounded-xl bg-blue-400 text-white flex justify-center items-center "
                >
                  <span>Satın Al</span>
                </NavLink>
              )}
              <span className="text-md">
                {item.packet.price === "0"
                  ? "İletişime geçin"
                  : "Fiyat " + formatter.format(item.packet.price) + "₺"}{" "}
              </span>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Price;
