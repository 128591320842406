import React from "react";

const Iade = () => {
  return (
    <main className="h-screen w-full flex justify-center items-center">
      <div className="h-full w-3/5 border flex flex-col justify-center items-center px-20 ">
        <h1 className="text-3xl">İade Politikası</h1>
        <p>
          1. İade Süresi: Müşteriler, ürünlerini teslim aldıkları tarihten
          itibaren 14 içinde iade talebinde bulunabilirler.
        </p>
        <p>
          2. İade İşlemi: İade talebinizi işlemek için info@fuatayazgrup.com
          üzerinden müşteri hizmetleri ekibimize başvurabilirsiniz. İade
          talebiniz incelendikten sonra, iade işlemi onaylandığında size gerekli
          talimatlar verilecektir. İade nakliye masrafları müşteriye aittir ve
          iade işlemi tamamlandığında iade edilen ürünlerin tam bedeli
          müşterinin ödeme yöntemine iade edilecektir.
        </p>
        <p>
          3. İade İstisnaları: Bazı özel ürünler veya hizmetler iade
          edilemeyebilir veya iade süresi içinde iade edilemeyebilir. Bu tür
          istisnalar hakkında bilgi almak için müşteri hizmetleri ekibimizle
          iletişime geçiniz.
        </p>
        <p>
          4. Müşteri Desteği: İade politikamız veya işlemi hakkında herhangi bir
          sorunuz veya endişeniz varsa, lütfen info@fuatayazgrup.com üzerinden
          müşteri hizmetleri ekibimizle iletişime geçin. Size yardımcı olmaktan
          mutluluk duyarız.
        </p>
        <p>
          5. Politika Değişiklikleri: İade politikamız zaman zaman
          güncellenebilir veya değiştirilebilir. Bu nedenle, iade işlemi
          yapmadan önce en güncel politikaları kontrol etmekte fayda vardır. Bu
          örnek iade politikası taslağı, müşterilere ürün iade işlemi hakkında
          genel bilgi vermek için kullanılabilir. İşletmenizin ihtiyaçlarına ve
          özel koşullarına göre politikayı uyarlayabilirsiniz. İade politikanızı
          web sitenizde veya müşterilerin erişebileceği diğer platformlarda
          yayınlamak önemlidir. Ayrıca, yerel yasal gereksinimlere ve tüketici
          haklarına uyduğunuzdan emin olun.
        </p>
      </div>
    </main>
  );
};

export default Iade;
