import React, { useEffect, useState } from "react";
import "aos/dist/aos.css";
import TextListFunction from "../../utils/text";

const Banner = () => {
  const screenWidth = window.innerWidth;
  const textlist = ["Web Uygulamaları", "Mobil Yazılımlar", "Ar-Ge Projeler"];
  const sliderImages = [
    { url: "/image/erce_mac.png", site_link: "https://erceglobal.com" },
    { url: "/image/fn_mac.png", site_link: "https://fnaksesuar.com" },
    {
      url: "/image/genc_mac.png",
      site_link: "https://antalyayazicitamiri.net/",
    },
    {
      url: "/image/ren-mac.png",
      site_link: "https://antalyayazicitamiri.net/",
    },
    {
      url: "/image/fit-mac.png",
      site_link: "https://antalyayazicitamiri.net/",
    },
    {
      url: "/image/disci-mac.png",
      site_link: "https://antalyayazicitamiri.net/",
    },
    {
      url: "/image/mimar-mac.png",
      site_link: "https://antalyayazicitamiri.net/",
    },
    {
      url: "/image/course-mac.png",
      site_link: "https://antalyayazicitamiri.net/",
    },
    {
      url: "/image/otel-mac.png",
      site_link: "https://antalyayazicitamiri.net/",
    },
    {
      url: "/image/av1-mac.png",
      site_link: "https://antalyayazicitamiri.net/",
    },
    {
      url: "/image/etc1-mac.png",
      site_link: "https://antalyayazicitamiri.net/",
    },
    {
      url: "/image/otel2-mac.png",
      site_link: "https://antalyayazicitamiri.net/",
    },
    {
      url: "/image/otel3-mac.png",
      site_link: "https://antalyayazicitamiri.net/",
    },
    // Diğer resimler
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const totalImages = sliderImages.length;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === totalImages - 1 ? 0 : prevIndex + 1
      );
    }, 3000); // Geçiş süresi: 3000ms = 3 saniye (ayarlayabilirsin)

    return () => clearInterval(interval);
  }, [totalImages]);

  // const nextSlide = () => {
  //     setCurrentIndex((prevIndex) => (prevIndex === totalImages - 1 ? 0 : prevIndex + 1));
  // };

  // const prevSlide = () => {
  //     setCurrentIndex((prevIndex) => (prevIndex === 0 ? totalImages - 1 : prevIndex - 1));
  // };

  return (
    <div
      className="w-full bg-white "
      data-aos="fade-up"
      data-aos-easing="ease-in-sine"
    >
      {screenWidth > 1024 ? (
        <div className="relative flex justify-around items-center  w-full h-screen  text-black">
          <img
            src={sliderImages[currentIndex].url}
            alt=""
            className="object-cover  w-2/5 "
          />

          <div className="flex flex-col justify-center items-center text-2xl">
            <img
              src="/image/color.png"
              className="object-cover w-2/5 absolute top-20 z-1 left-[45%]"
            />
            <div className="flex gap-2 z-10 border border-white bg-purple-800 text-white w-[420px] px-2 h-20 justify-center items-center rounded-full">
              <span>Sizin için </span>
              <TextListFunction textList={textlist} />
            </div>
            <span className="text-md mt-5 z-10 italic cursive ">
              Sıradan değil, özel yazılımlar
            </span>
          </div>
        </div>
      ) : (
        <div className="relative flex flex-col  w-full h-screen  text-black">
          <div className="absolute top-24 w-full h-screen flex flex-col   text-black px-3 md:px-10">
            <img
              src={sliderImages[currentIndex].url}
              alt=""
              className="object-fill w-full h-2/5 "
            />
            <div className=" flex flex-col justify-center items-center  text-2xl">
              <span className="text-md mb-3 italic cursive">
                Standart değil, özel yazılımlar
              </span>
              <div className="flex gap-2 border border-white bg-black text-white w-96 px-2 h-20 justify-center items-center rounded-full">
                <span>Sizin için </span>
                <TextListFunction textList={textlist} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Banner;
