import { useEffect, useState } from "react"

function TextListFunction({ textList }) {
    const [currentText, setCurrentText] = useState('')
    const [currentIndex, setCurrentIndex] = useState(0)

    useEffect(() => {
        if (currentIndex < textList.length) {
            const text = textList[currentIndex]
            const currentCharIndex = currentText.length

            if (currentCharIndex < text.length) {
                const timeoutId = setTimeout(() => {
                    setCurrentText(text.substring(0, currentCharIndex + 1))
                }, 130)

                return () => clearTimeout(timeoutId)
            } else {
                setTimeout(() => {
                    setCurrentIndex((currentIndex + 1) % textList.length)
                    setCurrentText('')
                }, 1000)
            }
        }
    }, [currentText, currentIndex, textList])

    return <p className='  italic cursive '>{currentText}</p>
}


export default TextListFunction