import React from "react";
import { FcDownRight } from "react-icons/fc";
import "aos/dist/aos.css";
const About = () => {
  return (
    <div className=" min-h-screen  w-full flex flex-col gap-5 justify-center ">
      <div className="flex flex-col gap-20 justify-center items-center h-1/2">
        <div data-aos="fade-down" className="text-3xl font-mono">
          Hizmetlerimiz
        </div>
        <div className="flex flex-wrap justify-evenly items-center gap-10">
          <div
            data-aos="fade-right"
            data-aos-duration="200"
            className="border h-14 lg:h-10 w-1/3 lg:w-1/5 rounded-lg flex justify-center items-center "
          >
            <FcDownRight />{" "}
            <p className="text-red-500 font-medium">Web Uygulamalar</p>
          </div>
          <div
            data-aos="fade-right"
            data-aos-duration="300"
            className="border h-14 lg:h-10 w-1/3 lg:w-1/5 rounded-lg flex justify-center items-center"
          >
            <FcDownRight />{" "}
            <p className="text-green-500 font-medium">E-Ticaret</p>{" "}
          </div>
          <div
            data-aos="fade-left"
            data-aos-duration="400"
            className="border h-14 lg:h-10 w-1/3 lg:w-1/5 rounded-lg flex justify-center items-center"
          >
            <FcDownRight />
            <p className="text-orange-500 font-medium">
              Restorant Sistemleri
            </p>{" "}
          </div>
          <div
            data-aos="fade-left"
            data-aos-duration="500"
            className="border h-14 lg:h-10 w-1/3 lg:w-1/5 rounded-lg flex justify-center items-center"
          >
            <FcDownRight />{" "}
            <p className="text-purple-500 font-medium">Randevu Sistemleri</p>
          </div>
          <div
            data-aos="fade-right"
            data-aos-duration="600"
            className="border h-14 lg:h-10 w-1/3 lg:w-1/5 rounded-lg flex justify-center items-center"
          >
            <FcDownRight />
            <p className="text-yellow-500 font-medium">
              Mobil Uygulamalar
            </p>{" "}
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="700"
            className="border h-14 lg:h-10 w-1/3 lg:w-1/5 rounded-lg flex justify-center items-center"
          >
            <FcDownRight />{" "}
            <p className="text-pink-500 font-medium">Medya Yönetimi</p>
          </div>
          <div
            data-aos="fade-left"
            data-aos-duration="800"
            className="border h-14 lg:h-10 w-1/3 lg:w-1/5 rounded-lg flex justify-center items-center"
          >
            <FcDownRight />{" "}
            <p className="text-red-500 font-medium">Özel Yazılımlar</p>
          </div>
        </div>
        <div className="flex flex-wrap justify-evenly items-center gap-5">
          <div
            data-aos="fade-right"
            data-aos-duration="1000"
            className="border-b h-24 w-72 px-3 rounded-lg flex flex-col justify-center items-center "
          >
            <div className="font-bold">Geliştirilen Web Uygulamalar</div>
            <div className="font-medium"> 890</div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="1000"
            className="border-b h-24 w-72 px-3 rounded-lg flex flex-col justify-center items-center "
          >
            <div className="font-bold">Aktif Kullanıcı</div>
            <div className="font-medium"> 50</div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="1000"
            className="border-b h-24 w-72 px-3 rounded-lg flex flex-col justify-center items-center "
          >
            <div className="font-bold">Aktif İşletme</div>
            <div className="font-medium"> 93</div>
          </div>
          <div
            data-aos="fade-left"
            data-aos-duration="1000"
            className="border-b h-24 w-72 px-3 rounded-lg flex flex-col justify-center items-center "
          >
            <div className=" font-bold">Mutlu Müşteriler</div>
            <div className="font-medium"> 1033</div>
          </div>
        </div>

        <div
          data-aos="fade-right"
          data-aos-duration="800"
          className="flex flex-col justify-center items-center rounded-lg bg-[#17171A] w-96 text-white"
        >
          <div
            data-aos="fade-right"
            data-aos-duration="1000"
            className="text-2xl"
          >
            "Dijital Geleceği Birlikte Yazalım"
          </div>
          <img
            data-aos="fade-right"
            data-aos-duration="1500"
            src="/image/imza.png"
            alt="imza"
          />
        </div>
      </div>
    </div>
  );
};

export default About;
