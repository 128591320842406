import React, { useEffect, useState } from "react";
import Navbar from "../../components/common/header/Navbar";

import AOS from "aos";
import "aos/dist/aos.css";

import { NavLink } from "react-router-dom";
import { FcDownRight } from "react-icons/fc";
const Home = ({ sepet, setSepet }) => {
  const [data, setData] = useState(null);
  const [kategori, setKategori] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [selectKategori, setSelectKategori] = useState(null);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const screenWidth = window.innerWidth;
  const fetchProduct = async () => {
    try {
      const response = await fetch(`https://renamesoft.com/r_api/api.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          q: "urunler",
        }),
      });

      const data = await response.json();

      if (data.urunler) {
        setData(data.urunler);
        setFilteredData(data.urunler);
      }

      if (data.error) {
        console.error("Sunucu hatası:", data.error);
      }
    } catch (err) {
      console.error("Bir hata oluştu:", err);
    }
  };

  const fetchKategori = async () => {
    try {
      const response = await fetch(`https://renamesoft.com/r_api/api.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          q: "kategori",
        }),
      });

      const data = await response.json();

      if (data.kategoriler) {
        setKategori(data.kategoriler);
      }

      if (data.error) {
        console.error("Sunucu hatası:", data.error);
      }
    } catch (err) {
      console.error("Bir hata oluştu:", err);
    }
  };

  useEffect(() => {
    AOS.init();
  }, []);
  useEffect(() => {
    fetchProduct();
    fetchKategori();
  }, []);

  useEffect(() => {
    if (selectKategori) {
      setFilteredData(data.filter((item) => item.c_id == selectKategori));
    } else {
      setFilteredData(data);
    }
  }, [selectKategori]);
  return (
    <main>
      <Navbar setSepet={setSepet} sepet={sepet} />
      <div className="absolute w-full flex justify-center h-[91vh] items-center top-20">
        {screenWidth < 1024 ? (
          showMobileMenu && (
            <div className="w-full z-10 absolute left-0  pl-5 pt-5 h-full flex flex-col   overflow-y-auto gap-2 bg-white ">
              <div className="w-full  flex flex-col justify-center  gap-3 ">
                <button
                  onClick={() => setShowMobileMenu(false)}
                  className="w-full text-start  font-mono text-gray-700 "
                >
                  Kapat
                </button>
                <button
                  onClick={() => {
                    setSelectKategori(null);
                    setShowMobileMenu(false);
                  }}
                  className="w-full text-start  font-mono text-gray-700 "
                >
                  Hepsini Göster
                </button>
                {kategori &&
                  kategori.map((item, index) => (
                    <button
                      onClick={() => {
                        setSelectKategori(item.id);
                        setShowMobileMenu(false);
                      }}
                      className="w-full text-start flex gap-2   font-mono text-gray-700 "
                    >
                      <FcDownRight className="w-4 h-4 text-blue-400" />
                      {item.name}
                    </button>
                  ))}
              </div>
            </div>
          )
        ) : (
          <div className="lg:w-1/6 pl-5 pt-5 h-full flex flex-col  overflow-y-auto gap-2 ">
            <div className="w-2/3 self-end flex flex-col gap-3">
              <button
                onClick={() => setSelectKategori(null)}
                className="w-full text-start  font-serif text-gray-700 "
              >
                Hepsini Göster
              </button>
              {kategori &&
                kategori.map((item, index) => (
                  <button
                    onClick={() => setSelectKategori(item.id)}
                    className="w-full text-start flex items-center  gap-2 font-mono text-gray-700 "
                  >
                    <FcDownRight className="w-4 h-4 text-blue-400" />
                    {item.name}
                  </button>
                ))}
            </div>
          </div>
        )}

        <div className="lg:w-4/6 z-2 w-full p-5  h-full flex flex-wrap  md:justify-center gap-2 overflow-y-auto ">
          {screenWidth < 1024 && (
            <button className="w-full" onClick={() => setShowMobileMenu(true)}>
              Kategorileri göster
            </button>
          )}
          {filteredData &&
            filteredData.map((item, index) => (
              <div
                key={index}
                className="xl:w-64 relative lg:w-52  md:w-52 w-full border  h-72"
              >
                <div className="w-full h-2/3 border-b">
                  <img
                    src={item.image}
                    alt={item.name}
                    className="w-full h-full object-fill"
                  />
                </div>
                <div className="w-full h-1/3 flex flex-col justify-around items-center ">
                  <h2>{item.name}</h2>
                  <NavLink
                    to={"/yazilimlar/" + item.seo + ""}
                    className={
                      "h-10 w-2/3 border rounded-xl bg-blue-400 text-white flex justify-center items-center "
                    }
                  >
                    Detaylara Git
                  </NavLink>
                </div>
                <div className="absolute top-0 right-0 bg-orange-400 w-32 text-white  flex justify-center items-center">
                  {item.title}
                </div>
              </div>
            ))}
        </div>
        <div className=" hidden   w-1/6 lg:flex flex-col gap-4 h-full ">
          <div className="w-full flex flex-col justify-center gap-4 items-center h-36 border">
            <h4 className="font-mono">Mobil Yazılımları</h4>
            <NavLink
              to={"/kategoriler/mobil"}
              className="w-40 h-10 flex justify-center items-center bg-indigo-400 text-white rounded-lg "
            >
              Detaylara Git
            </NavLink>
          </div>
          <div className="w-full flex flex-col justify-center gap-4 items-center h-36 border">
            <h4 className="font-mono">Otel Yazılımları</h4>
            <NavLink
              to={"/kategoriler/otel"}
              className="w-40 h-10 flex justify-center items-center bg-pink-400 text-white rounded-lg "
            >
              Detaylara Git
            </NavLink>
          </div>
          <div className="w-full flex flex-col justify-center gap-4 items-center h-36 border">
            <h4 className="font-mono">Restorant Yazılımları</h4>
            <NavLink
              to={"/kategoriler/restorant"}
              className="w-40 h-10 flex justify-center items-center bg-red-400 text-white rounded-lg "
            >
              Detaylara Git
            </NavLink>
          </div>
          <div className="w-full flex flex-col justify-center gap-4 items-center h-36 border">
            <h4 className=" font-mono  ">Randevu Yazılımları</h4>
            <NavLink
              to={"/kategoriler/randevu"}
              className="w-40 h-10 flex justify-center items-center bg-orange-400 text-white rounded-lg "
            >
              Detaylara Git
            </NavLink>
          </div>
          <div className="w-full flex flex-col justify-center gap-4 items-center h-36 border">
            <h4 className="font-mono">Live Chat Uygulamaları</h4>
            <NavLink
              to={"/kategoriler/chat"}
              className="w-40 h-10 flex justify-center items-center bg-yellow-400 text-white rounded-lg "
            >
              Detaylara Git
            </NavLink>
          </div>
          <div className="w-full flex flex-col justify-center gap-4 items-center h-36 border">
            <h4 className="font-mono">E-Ticaret Yazılımları</h4>
            <NavLink
              to={"/kategoriler/eticaret"}
              className="w-40 h-10 flex justify-center items-center bg-blue-400 text-white rounded-lg "
            >
              Detaylara Git
            </NavLink>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Home;
