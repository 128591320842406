import React, { useEffect } from 'react'
import Navbar from '../../components/common/header/Navbar'
import Footer from '../../components/common/footer/Footer'
import { useNavigate } from 'react-router-dom'

const Bilgiler = () => {

    useEffect(() => {
        fetch('https://renamesoft.com/r_api/api.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                q: "GET_INFORMATION"
            })
        }).then(res => res.json())
            .then(data => {
                if (data.status) {
                    window.location.href = "satin-al.php"
                }
            })
    }, [])
    const [data, setData] = React.useState({
        name: "",
        lastname: "",
        phone: "",
        email: "",
        address: "",
        q: "ADD_INFORMATION"
    })
    const onChange = (e) => {
        const { name, value } = e.target
        setData({
            ...data,
            [name]: value
        })
    }
    const onSubmit = (e) => {
        e.preventDefault()
        if (!data.name || !data.lastname || !data.phone || !data.email || !data.address) {
            alert("Lütfen tüm alanları doldurunuz.")
            return
        }
        fetch('https://renamesoft.com/r_api/api.php', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(data => {
                if (data.status) {
                    window.location.href = "satin-al.php"
                }
            }).catch(err => {
                alert("Bir hata oluştu. Lütfen tekrar deneyiniz.")
            })
    }
    return (
        <main>
            <Navbar />
            <div className='absolute top-24' >
                <div className='w-full h-screen flex justify-center items-center' >
                    <div className=' w-full md:w-3/5 lg:w-2/5 flex flex-col  justify-center items-center' >
                        <h1 className='text-xl font-semibold mb-10' >Fatura ve iletisim bilgileri</h1>
                        <div className='flex flex-col gap-5 w-4/5'>
                            <input onChange={onChange} type="text" name="name" id="name" placeholder='Adınız' className='w-full pl-2 h-10 border outline-none' />
                            <input onChange={onChange} type="text" name="lastname" id="lastname" placeholder='Soy Adınız' className='w-full pl-2 h-10 border outline-none' />
                            <input onChange={onChange} type="text" name="phone" id="phone" placeholder='Telefon numaranız' className='w-full pl-2 h-10 border outline-none' />
                            <input onChange={onChange} type="text" name="email" id="email" placeholder='E-mail Adresiniz' className='w-full pl-2 h-10 border outline-none' />
                            <textarea onChange={onChange} name="address" id="address" placeholder='Adresiniz' className='w-full h-20 pl-2 border outline-none' cols="30" rows="10"></textarea>
                            <div onClick={onSubmit} className='w-1/5 self-end bg-blue-500 text-white h-10 flex justify-center items-center' >Devam Et</div>
                        </div>


                    </div>
                </div>
                <Footer />
            </div>
        </main>
    )
}

export default Bilgiler