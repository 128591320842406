import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { PiBasketThin } from "react-icons/pi";
const Navbar = ({ sepet, setSepet }) => {
  const screenWidth = window.innerWidth;
  const [showMenu, setShowMenu] = React.useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const header = document.querySelector("header");
      if (header) {
        header.classList.toggle("sticky", window.scrollY > 400);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
      window.requestAnimationFrame(scrollToTop);
      window.scrollTo(0, c - c / 80);
    }
  };

  const onClickLogo = () => {
    scrollToTop();
  };
  return (
    <header
      style={{ backgroundColor: "#E3E5E6" }}
      className="flex z-10 bg-[#E3E5E6]  px-5 absolute  bg-transparent justify-between items-center h-20 border-b w-full"
    >
      <NavLink to={"/"} className="flex justify-center pl-5 items-center">
        <img
          src="/image/logo.png"
          alt="logo"
          className="object-cover  h-14 w-14 lg:h-16 lg:w-16"
        />
        <div className="flex flex-col justify-center items-center">
          <div className="text-2xl font-semibold">Renamesoft</div>
          <div className="text-sm font-thin">Change The Future</div>
        </div>
      </NavLink>
      {screenWidth > 1024 ? (
        <div className="flex text-md font-semibold gap-5 ">
          <NavLink to="/">Anasayfa</NavLink>
          <NavLink to={"/hakkimizda"}>Hakkımızda</NavLink>
          <NavLink to={"/yazilimlar"}>Yazılımlarımız</NavLink>
          <NavLink to={"/iletisim"}>İletişim</NavLink>
          <NavLink to={"/sepet"} className="flex ">
            <PiBasketThin className="h-6 w-6" />
            <span>{sepet ? sepet.length : 0}</span>
          </NavLink>
        </div>
      ) : (
        <div className="flex  text-md gap-5 ">
          <span onClick={() => setShowMenu(true)}>Menu</span>
          {showMenu && (
            <div className="absolute z-99 right-0 top-20  w-full h-72 bg-white">
              <div className="flex flex-col gap-5 justify-center items-center ">
                <NavLink to="/">Anasayfa</NavLink>
                <NavLink to={"/yazilimlar"}>Yazılımlarımız</NavLink>
                <NavLink to={"/iletisim"}>İletişim</NavLink>
                <NavLink to={"/sepet"} className="flex ">
                  <PiBasketThin className="h-6 w-6" />
                  <span>{sepet ? sepet.length : 0}</span>
                </NavLink>
              </div>
            </div>
          )}
        </div>
      )}
    </header>
  );
};

export default Navbar;
