import React from 'react'
import Navbar from '../../components/common/header/Navbar'
import Footer from '../../components/common/footer/Footer'
import { NavLink, useParams } from 'react-router-dom'

const Callback = () => {
    const { status } = useParams()
    return (
        <main>
            <Navbar />
            <div className='absolute top-24' >
                <div className="flex justify-center items-center h-screen">
                    <div className='w-1/6 h-96 border flex flex-col justify-center items-center'>
                        {status === "success" ? (
                            <div className='flex flex-col justify-center items-center' >
                                <div className='text-3xl'>Tebrikler</div>
                                <div className='text-xl text-green-500'>Sipariş alındı.</div>
                                <div className='text-center'>En kısa sürede mail adresinize dönüş yapılacaktır.</div>
                            </div>
                        ) : (
                            <div className='text-red-500 text-center'>Satın alım sırasında bir hata oluştu daha sonra tekrar deneyiniz</div>
                        )}
                        <NavLink to="/">
                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" >
                                Ana Sayfaya Dön
                            </button>
                        </NavLink>
                    </div>
                </div>
                <Footer />
            </div>
        </main>
    )
}

export default Callback