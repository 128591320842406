import { Route, Routes } from "react-router-dom";
import Home from "./pages/home/Home";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import Projeler from "./pages/Projeler/Projeler";
import { useEffect, useState } from "react";
import Sepet from "./pages/sepet/Sepet";
import Yazilimlar from "./pages/yazilimDetay/Yazilimlar";
import Bilgiler from "./pages/odeme/Bilgiler";
import Callback from "./pages/odeme/Callback";
import Gizlilik from "./pages/gizlilik/Gizlilik";
import Iade from "./pages/iade/Iade";
import CategoryDetay from "./pages/CategoryDetay/CategoryDetay";
function App() {
  const [sepet, setSepet] = useState([]);
  const fetchSepet = async () => {
    try {
      const response = await fetch(`https://renamesoft.com/r_api/api.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          q: "sepetim",
        }),
      });

      const data = await response.json();
      console.log(data);
      if (data.sepet) {
        setSepet(data.sepet);
      }

      if (data.error) {
        console.error("Sunucu hatası:", data.error);
      }
    } catch (err) {
      console.error("Bir hata oluştu:", err);
    }
  };
  useEffect(() => {
    fetchSepet();
  }, []);
  return (
    <Routes>
      <Route
        path="/hakkimizda"
        element={<About setSepet={setSepet} sepet={sepet} />}
      />
      <Route
        path="/iletisim"
        element={<Contact setSepet={setSepet} sepet={sepet} />}
      />
      <Route
        path="/yazilimlar/:name"
        element={<Yazilimlar setSepet={setSepet} sepet={sepet} />}
      />
      <Route
        path="/kategoriler/:name"
        element={<CategoryDetay setSepet={setSepet} sepet={sepet} />}
      />
      <Route
        path="/yazilimlar"
        element={<Projeler setSepet={setSepet} sepet={sepet} />}
      />
      <Route
        path="/sepet"
        element={<Sepet setSepet={setSepet} sepet={sepet} />}
      />
      <Route
        path="/odeme"
        element={<Bilgiler setSepet={setSepet} sepet={sepet} />}
      />
      <Route path="/callback/:status" element={<Callback />} />
      <Route path="/gizlilik" element={<Gizlilik />} />
      <Route path="/iade-politikasi" element={<Iade />} />
      <Route path="/" element={<Home setSepet={setSepet} sepet={sepet} />} />
    </Routes>
  );
}

export default App;
