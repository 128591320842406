import React, { useEffect } from 'react'
import Navbar from '../../components/common/header/Navbar'
import Banner from '../../components/banner/Banner'
import About from '../../components/about/About'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Contact from '../../components/contact/Contact';
import Projeler from '../../components/Projects/Projeler';
import Price from '../../components/prices/price';
import Footer from '../../components/common/footer/Footer';
import Destek from '../../components/common/footer/Destek';
import ProjeCart from '../../components/projeCart/ProjeCart';
const Home = ({ sepet, setSepet }) => {
    useEffect(() => {
        AOS.init();
    }, [])
    return (
        <main >
            <Navbar setSepet={setSepet} sepet={sepet} />
            <Banner />
            <About />
            <Projeler />
            <ProjeCart />
            <Price />
            <Contact />

            <Footer />
            <Destek />
        </main>
    )
}

export default Home